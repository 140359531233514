export const SOCKET = 'SOCKET'

export function socketaction(socket) {
    return {
        type: SOCKET,
        payload: socket
    }
}

//addomessage
