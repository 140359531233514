const typeState = {
    contact: 'contact',
    motion: 'occupancy',
    pressure: 'water_leak',
    vibration: 'vibration'
}

export const stateFiller = (property, action) => {
    const { type, payload } = action

    switch (type) {
        case 'stateUpdate': {
            const { device, states } = payload
            return getInjectedProperty(property, device, states)
        }
        case 'init': {
            return payload
        }
        default:
            return property
    }
}

export const getInjectedProperty = (currentProperty, deviceName, newStates) => {
    return {
        ...currentProperty,
        rooms: currentProperty.rooms.map(room => ({
            ...room,
            roomElements: room.roomElements.map(element => ({
                ...element,
                devices: element.devices.map(device => {
                    const { deviceType } = device
                    const usefullState = typeState[deviceType]
                    const historyLength = device.history.length
                    const currentStates = historyLength && device.history[historyLength - 1]
                    return device.friendly_name === deviceName &&
                        (!currentStates || currentStates[usefullState] !== newStates[usefullState])
                        ? { ...device, history: [...device.history, newStates] }
                        : device
                })
            }))
        }))
    }
}
