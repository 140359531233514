import {
    SIZEINPUTCOMPOSE,
    POSITIONCOMPOSE,
    SEARCHMESSAGEVALUE,
    SEARCHMESSAGE,
    ADDCOMMENT,
    MESSAGE,
    CONVERSATION,
    LISTCONV,
    MESSAGES,
    LISTPERSONSEARCH,
    CHATGRPINFO,
    CONVID,
    DELETECONV,
    INCOMMINGCALL
} from '../actions/actions'

const omessage = store => next => action => {
    let result = next(action)

    if (action.type === CONVERSATION) {
        return { auth: true, conversation: action.payload }
    }

    if (action.type === SIZEINPUTCOMPOSE) {
        return { auth: true, sizeinputcompose: action.payload }
    }

    if (action.type === POSITIONCOMPOSE) {
        return { auth: true, positioncompose: action.payload }
    }

    if (action.type === INCOMMINGCALL) {
        return { auth: true, incommincal: action.payload }
    }

    if (action.type === SEARCHMESSAGE) {
        return { auth: true, searchmessage: action.payload }
    }
    if (action.type === SEARCHMESSAGEVALUE) {
        return { auth: true, searchmessagevalue: action.payload }
    }

    if (action.type === ADDCOMMENT) {
        return { auth: true, addcomment: action.payload }
    }

    if (action.type === MESSAGE) {
        return { auth: true, message: action.payload }
    }

    if (action.type === CONVID) {
        return { auth: true, convid: action.payload }
    }

    if (action.type === DELETECONV) {
        return { auth: true, convid: action.payload, chatgrpinfo: action.payload }
    }

    if (action.type === LISTCONV) {
        return { auth: true, listconv: action.payload }
    }

    if (action.type === CHATGRPINFO) {
        return { auth: true, chatgrpinfo: action.payload }
    }

    if (action.type === MESSAGES) {
        return { auth: true, messages: action.payload }
    }

    if (action.type === LISTPERSONSEARCH) {
        return { auth: true, listpersonsearch: action.payload }
    }

    return result
}

export default omessage
