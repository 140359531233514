import BedIcon from '../../icons/iot/bed.svg'
import EntranceIcon from '../../icons/iot/log-in.svg'
import KitchenIcon from '../../icons/iot/cutlery.svg'
import SofaIcon from '../../icons/iot/sofa.svg'
import BathroomIcon from '../../icons/iot/bathroom.svg'
import ToiletIcon from '../../icons/iot/toilet.svg'
import TerraceIcon from '../../icons/iot/dinner-table.svg'

import IotIcon from '../../icons/iot/graph.svg'
import LeftArrowIcon from '../../icons/iot/left-arrow.svg'
import LogbookIcon from '../../icons/iot/open-book.svg'

import DoorIcon from '../../icons/iot/smart-door.svg'
import MotionIcon from '../../icons/iot/sensor.svg'
import PressureIcon from '../../icons/iot/barometer.svg'

export const getLogoSrc = type => {
    const roomRegex = /room\d{1}/gi
    const isRoomType = roomRegex.test(type)
    const isPressureType = ['pressure', 'bedPressure'].some(pType => type === pType)

    if (isRoomType) return BedIcon
    if (type === 'entrance') return EntranceIcon
    if (type === 'kitchen') return KitchenIcon
    if (type === 'salon') return SofaIcon
    if (type === 'bedroom') return BedIcon
    if (type === 'bathroom') return BathroomIcon
    if (type === 'toilet') return ToiletIcon
    if (type === 'terrace') return TerraceIcon

    if (type === 'iot-logo') return IotIcon
    if (type === 'leftArrow') return LeftArrowIcon
    if (type === 'logbook') return LogbookIcon

    if (type === 'motion') return MotionIcon
    if (isPressureType) return PressureIcon
    return DoorIcon
}
