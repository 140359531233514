import {
    GRPNOTIFICATIONS,
    PROS,
    PECHADS,
    ROLE,
    ROLES,
    MYSTRUCTURE,
    USERINSTRUCTURE,
    ADDUSERINSTRUCTURE,
    COMPANIES
} from '../actions/actions'

const initialState = {
    mystructure: [],
    grpnotifications: [],
    userinstructure: [],
    ADDUSERINSTRUCTURE: '',
    roles: [],
    role: {},
    pechads: [],
    pros: [],
    companies: []
}

const coreReducer = (state = initialState, action) => {
    switch (action.type) {
        case MYSTRUCTURE:
            return {
                ...state,
                mystructure: action.payload
            }

        case GRPNOTIFICATIONS:
            return {
                ...state,
                grpnotifications: action.payload
            }

        case PROS:
            let _payload = []
            if (action.payload?.records.length) {
                action.payload.records.map(r => {
                    _payload.push(r.fields)
                })
            }

            return {
                ...state,
                pros: _payload
            }

        case PECHADS:
            return {
                ...state,
                pechads: action.payload
            }

        case ROLES:
            return {
                ...state,
                roles: action.payload
            }
        case ROLE:
            return {
                ...state,
                role: action.payload
            }

        case USERINSTRUCTURE:
            return {
                ...state,
                userinstructure: action.payload
            }

        case ADDUSERINSTRUCTURE:
            return {
                ...state,
                adduserinstructure: action.payload
            }

        case COMPANIES:
            return {
                ...state,
                companies: action.payload
            }

        default:
            return state
    }
}

export default coreReducer
