import PropTypes from 'prop-types'
import React from 'react'

import Bubble from '../Bubble/Bubble'

import './IconBubble.scss'

const IconBubble = ({ alt, onClick, size, src }) => (
    <Bubble background="#2E3F5E" border="#2E3F5E" className="IconBubble mx-1" size={size}>
        <img src={src} className="IconBubble__icon" alt={alt} onClick={onClick} />
    </Bubble>
)

IconBubble.propTypes = {
    alt: PropTypes.string,
    onClick: PropTypes.func,
    src: PropTypes.string.isRequired
}

IconBubble.defaultProps = {
    alt: 'Icon',
    onClick: () => {},
    size: 37
}

export default IconBubble
