const STATE_KEY = 'activities'

export const selectActivities = state => state[STATE_KEY]

/**
 *
 * @param {object} state
 * @param {string} structureId
 */

export const selectActivitiesByStructureId = (state, structureId) => {
    const activities = selectActivities(state)
    return activities.filter(({ structure }) => structure === structureId)
}
