import { SOCKET } from '../actions/actions'

const initialState = {
    socket: ''
}

const socketReducer = (state = initialState, action) => {
    switch (action.type) {
        case SOCKET:
            return {
                ...state,
                socket: action.payload
            }

        default:
            return state
    }
}

export default socketReducer
