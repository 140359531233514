import axios from 'axios'

import config from '../config'
import { getFromSessionStorage } from '../utils/manageSessionStorage'

const ROOT_URL = config.url_gateway

/**
 *
 * @param {string} companyId
 */

export const getCompanyStructures = companyId => {
    const token = getFromSessionStorage('sessionToken')
    return axios.get(`${ROOT_URL}/core/structure-new/${companyId}`, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {[string]} structuresIds
 */

export const getUserStructures = structuresIds => {
    const token = getFromSessionStorage('sessionToken')
    return axios.post(`${ROOT_URL}/core/structure-new/structures`, structuresIds, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {object} structureData
 */

export const addStructure = structureData => {
    const token = getFromSessionStorage('sessionToken')
    return axios.post(`${ROOT_URL}/core/structure-new`, structureData, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {object} structureData
 */

export const updateStructure = structureData => {
    const token = getFromSessionStorage('sessionToken')
    return axios.put(`${ROOT_URL}/core/structure-new`, structureData, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {string} companyId
 * @param {string} structureId
 */

export const deleteStructure = (companyId, structureId) => {
    const token = getFromSessionStorage('sessionToken')
    return axios.delete(`${ROOT_URL}/core/structure-new/${companyId}/${structureId}`, {
        headers: { 'x-session': token }
    })
}
