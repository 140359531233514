import axios from 'axios'
import config from '../config'
import { getFromSessionStorage } from '../utils/manageSessionStorage'

const ROOT_URL = config.url_gateway

export const getConversationFiles = async conversationId => {
    const token = getFromSessionStorage('sessionToken')
    return await axios.get(`${ROOT_URL}/messaging/conversation-files/${conversationId}`, {
        headers: { 'x-session': token }
    })
}

export const uploadFile = async (conversationId, file, fileName, fileType, replyAt) => {
    const token = getFromSessionStorage('sessionToken')
    return await axios.put(
        `${ROOT_URL}/messaging/file/${conversationId}` /*`${ROOT_URL}/messaging/file/${conversationId}`*/,
        { file, fileName, fileType, replyAt },
        {
            headers: { 'x-session': token }
        }
    )
}

export const getFile = async (conversationId, fileId) => {
    const token = getFromSessionStorage('sessionToken')
    return await axios.get(`${ROOT_URL}/messaging/file/${conversationId}/${fileId}`, {
        headers: { 'x-session': token }
    })
}
