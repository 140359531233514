import { DELETE_ACTIVITY } from './activities.actions'
import { DELETE_ROLE } from './roles.actions'
import { selectActivitiesByStructureId } from '../selectors/activities.selectors'
import { selectRolesByStructureId } from '../selectors/roles.selectors'
import { getCompanyStructures, getUserStructures, addStructure, updateStructure, deleteStructure } from '../../api/structures'

export const GET_COMPANY_STRUCTURES = 'GET_COMPANY_STRUCTURES'
export const GET_USER_STRUCTURES = 'GET_USER_STRUCTURES'
export const ADD_STRUCTURE = 'ADD_STRUCTURE'
export const UPDATE_STRUCTURE = 'UPDATE_STRUCTURE'
export const DELETE_STRUCTURE = 'DELETE_STRUCTURE'

/**
 *
 * @param {string} companyId
 */

export const getCompanyStructuresAction = companyId => async dispatch => {
    try {
        const { data: structures } = await getCompanyStructures(companyId)
        return dispatch({
            type: GET_COMPANY_STRUCTURES,
            payload: structures
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {[string]} structuresIds
 */

export const getUserStructuresAction = structuresIds => async dispatch => {
    try {
        const { data: structures } = await getUserStructures(structuresIds)
        return dispatch({
            type: GET_USER_STRUCTURES,
            payload: structures
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {object} structureData
 */

export const addStructureAction = structureData => async dispatch => {
    try {
        const { data: structure } = await addStructure(structureData)
        return dispatch({
            type: ADD_STRUCTURE,
            payload: structure
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {object} structureData
 */

export const updateStructureAction = structureData => async dispatch => {
    try {
        const { data: structure } = await updateStructure(structureData)
        return dispatch({
            type: UPDATE_STRUCTURE,
            payload: structure
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {string} companyId
 * @param {string} structureId
 */

export const deleteStructureAction = (companyId, structureId) => async (dispatch, getState) => {
    const state = getState()

    try {
        await deleteStructure(companyId, structureId)

        const deletedActivities = selectActivitiesByStructureId(state, structureId)
        const deletedRoles = selectRolesByStructureId(state, structureId)

        deletedActivities.forEach(({ _id }) => {
            dispatch({
                type: DELETE_ACTIVITY,
                payload: _id
            })
        })

        deletedRoles.forEach(({ _id }) => {
            dispatch({
                type: DELETE_ROLE,
                payload: _id
            })
        })

        return dispatch({
            type: DELETE_STRUCTURE,
            payload: structureId
        })
    } catch (err) {
        console.log(err)
    }
}
