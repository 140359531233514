import Cookies from 'universal-cookie'
const cookies = new Cookies()

/**
 *
 * @param {string} name
 */
export const getCookie = name => {
    return cookies.get(name)
}

/**
 *
 * @param {string} name
 * @param {*} value
 * @param {object} options
 */
export const setCookie = (name, value, options = {}) => {
    return cookies.set(name, value, options)
}

/**
 *
 * @param name
 */
export const removeCookie = name => {
    return cookies.remove(name)
}
