import {
    addParticipant,
    createGroupConversation,
    createOneToOneConversation,
    findUsersWithoutCompanyByName,
    getBase,
    getCompanyUsers,
    getConversations,
    getMessages,
    leaveOrDeleteConversation,
    removeParticipant,
    searchContact,
    searchUsers,
    sendMessage,
    updateGroupInfo,
    uploadUserImagesProfile
} from '../../api/messagingv2'
import { ALL_USERS, UNAUTH_USER, UPDATEIMAGES, USER } from './auth.actions'
import { CONVERSATION, CONVID, FREE_MESSAGES, LISTCONV, LISTPERSONSEARCH, MESSAGES } from './omessage.actions'
import decodeMessagesFromMemoryConversations from '../../utils/decodeMessagesFromMemoryConversations'
import decodeLastMessageConversations from '../../utils/decodeLastMessageConversations'
import { getLocalStorageItem } from '../../utils/manageLocalStorage'

export const WS_NEW_MESSAGE = 'WS_NEW_MESSAGE'
export const WS_NEW_CONVERSATION = 'WS_NEW_CONVERSATION'
export const DEFINE_NEW_LAST_MESSAGE = 'DEFINE_NEW_LAST_MESSAGE'
export const REMOVE_PARTICIPANTS = 'REMOVE_PARTICIPANTS'
export const ADD_PARTICIPANTS = 'ADD_PARTICIPANTS'
export const DELETE_CONVERSATION = 'DELETE_CONVERSATION'
export const WS_EDIT_CONVERSATION = 'WS_EDIT_CONVERSATION'
export const NEW_MESSAGE = 'NEW_MESSAGE'
export const COMPLETE_MESSAGE = 'COMPLETE_MESSAGE'
export const LIST_CONTACTS_FOR_ONE_TO_ONE = 'LIST_CONTACTS_FOR_ONE_TO_ONE'
export const UPDATE_USER_BASE = 'UPDATE_USER_BASE'
export const REMOVE_USER_FROM_ALL_USERS_COMPANY = 'REMOVE_USER_FROM_ALL_USERS_COMPANY'
export const ADD_USER_TO_ALL_USERS_COMPANY = 'ADD_USER_TO_ALL_USERS_COMPANY'
export const INCREMENT_MESSAGES = 'INCREMENT_MESSAGES'

export const getBaseAction = () => async dispatch => {
    try {
        const {
            data: {
                data: { conversations, ...user }
            },
            status
        } = await getBase()
        if (status === 200) {
            const store = getLocalStorageItem('hosmoz-store')
            delete store.omessage?.messages
            delete store.omessage?.conversation
            return dispatch({ type: USER, payload: user })
        } else {
            return dispatch({ type: UNAUTH_USER, payload: {} })
        }
    } catch (err) {}
}

export const getMessagesAction = (conversation, lastTimestamp) => async dispatch => {
    try {
        const {
            data: { status, data }
        } = await getMessages(conversation?.id, lastTimestamp)
        const messagesDecoded = await decodeMessagesFromMemoryConversations(data, conversation)
        if (status === 200) {
            if (messagesDecoded.length > 0 && !conversation.dataText)
                return !lastTimestamp
                    ? dispatch({ type: MESSAGES, payload: messagesDecoded })
                    : dispatch({ type: INCREMENT_MESSAGES, payload: messagesDecoded })
        }
    } catch (e) {}
}

export const getConversationsAction = () => async dispatch => {
    try {
        const {
            data: { data, status }
        } = await getConversations()
        if (status === 200) {
            const decodedConv = decodeLastMessageConversations(data)
            dispatch({ type: CONVERSATION, payload: decodedConv[0] })
            dispatch({ type: CONVID, payload: decodedConv[0]?.id })
            return dispatch({ type: LISTCONV, payload: decodedConv })
        }
    } catch (e) {}
}

export const updateGroupInfosAction = data => async () => {
    try {
        await updateGroupInfo(data)
    } catch (e) {
        throw new Error(e)
    }
}

export const addNewMessage = data => dispatch => {
    try {
        dispatch({
            type: DEFINE_NEW_LAST_MESSAGE,
            payload: [data]
        })
        return dispatch({ type: NEW_MESSAGE, payload: data })
    } catch (e) {
        throw new Error(e)
    }
}

export const sendMessageAction = message => async dispatch => {
    try {
        const {
            data: { data }
        } = await sendMessage(message)
        return dispatch({ type: COMPLETE_MESSAGE, payload: data })
    } catch (e) {
        throw new Error(e)
    }
}

export const removeParticipantAction = toRemove => async dispatch => {
    try {
        const {
            data: { data }
        } = await removeParticipant(toRemove)
        console.log(data)
    } catch (e) {
        throw new Error(e)
    }
}
/**
 *
 * @param search
 * @param typeOfConv {string} => only when we search users for one to one conv
 * @returns {(function(*): Promise<*|undefined>)|*}
 */
export const searchContactAction =
    (search, typeOfConv = '') =>
    async dispatch => {
        try {
            const {
                data: { data }
            } = await searchContact(search)
            if (typeOfConv === 'onetoone') return dispatch({ type: LIST_CONTACTS_FOR_ONE_TO_ONE, payload: data })
            else return dispatch({ type: LISTPERSONSEARCH, payload: data })
        } catch (e) {
            throw new Error(e)
        }
    }

export const resetContactsOneToOne = () => dispatch => dispatch({ type: LIST_CONTACTS_FOR_ONE_TO_ONE, payload: [] })

export const createGroupConversationAction = obj => async dispatch => {
    try {
        const {
            data: { data }
        } = await createGroupConversation(obj)
        dispatch({ type: CONVERSATION, payload: data.conversation })
        dispatch({ type: WS_NEW_CONVERSATION, payload: data.conversation })
        dispatch({ type: FREE_MESSAGES, payload: [] })
        return dispatch({ type: CONVID, payload: data.conversation.id })
    } catch (e) {
        throw new Error(e)
    }
}

export const createOneToOneConversationAction = obj => async dispatch => {
    try {
        const {
            data: { data }
        } = await createOneToOneConversation(obj)
        dispatch({ type: CONVERSATION, payload: data.conversation })
        dispatch({ type: WS_NEW_CONVERSATION, payload: data.conversation })
        return dispatch({ type: CONVID, payload: data.conversation.id })
    } catch (e) {
        throw new Error(e)
    }
}

export const leaveOrDeleteConversationAction = conversationId => async dispatch => {
    try {
        const {
            data: { data },
            status
        } = await leaveOrDeleteConversation(conversationId)
        console.log(data)
        if (status === 200 && data) return dispatch({ type: DELETE_CONVERSATION, payload: { id: conversationId } })
    } catch (e) {
        throw new Error(e)
    }
}

export const updateBaseUser = user => dispatch => {
    try {
        return dispatch({ type: UPDATE_USER_BASE, payload: user })
    } catch (e) {
        throw new Error(e)
    }
}

export const uploadUserImagesProfileAction = formData => async dispatch => {
    try {
        const {
            data: { data }
        } = await uploadUserImagesProfile(formData)
        return dispatch({ type: UPDATEIMAGES, payload: data })
    } catch (e) {
        throw new Error(e)
    }
}

export const getRecentConversationAction = id => dispatch => {
    try {
        return dispatch({})
    } catch (e) {
        throw new Error(e)
    }
}

export const searchUsersAction = str => async dispatch => {
    try {
        const {
            data: { data }
        } = await searchUsers(str)
        return dispatch({ type: LISTPERSONSEARCH, payload: data })
    } catch (e) {
        throw new Error(e)
    }
}

export const getCompanyUsersAction = companyId => async dispatch => {
    try {
        const {
            data: { data }
        } = await getCompanyUsers(companyId)
        return dispatch({ type: ALL_USERS, payload: data })
    } catch (e) {}
}

export const findUsersWithoutCompanyByNameAction = search => async dispatch => {
    try {
        const {
            data: { data }
        } = await findUsersWithoutCompanyByName(search)
        dispatch({ type: LISTPERSONSEARCH, payload: data })
    } catch (e) {}
}

export const removeUserFromAllUsersCompany = userId => async dispatch => {
    try {
        return dispatch({ type: REMOVE_USER_FROM_ALL_USERS_COMPANY, payload: userId })
    } catch (e) {}
}

export const addUserToAllUsersCompany = user => async dispatch => {
    try {
        return dispatch({ type: ADD_USER_TO_ALL_USERS_COMPANY, payload: user })
    } catch (e) {}
}
