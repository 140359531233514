import axios from 'axios'

import { getFromSessionStorage } from '../../utils/manageSessionStorage'
import { getCookie } from '../../utils/manageCookies'
import { setLocalStorageItem } from '../../utils/manageLocalStorage'
import { editMissingPro } from '../../api/planning'
import { fetchData } from '../../utils/fetchData'
const config = require('../../config')

const ROOT_URL = config.url_gateway
const CancelToken = axios.CancelToken
let cancel
export const PLANNING = 'PLANNING'
export const PLANNING_ALL = 'PLANNING_ALL'
export const EVENT = 'EVENT'
export const NEXTRDV = 'NEXTRDV'
export const LASTRDV = 'LASTRDV'
export const PRO_SELECTED = 'PRO_SELECTED'
export const ABSENCE_PRO = 'ABSENCE_PRO'
export const SEARCHEVENT = 'SEARCHEVENT'

export const freePlanning = () => async dispatch => {
    try {
        dispatch({ type: PLANNING, payload: [] })
    } catch (e) {
        throw new Error(e)
    }
}

export const freeSearchEvent = () => dispatch => {
    try {
        dispatch({ payload: [], type: SEARCHEVENT })
    } catch (e) {
        throw new Error(e)
    }
}

export const searchRdv = (search, structures) => {
    if (cancel !== undefined) {
        cancel()
    }
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .get(`${ROOT_URL}/planning/search_rdv?search=${search}&structures=${JSON.stringify(structures)}`, {
                headers: { 'x-session': token },
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c
                })
            })
            .then(({ data }) => {
                dispatch({ type: SEARCHEVENT, payload: data })
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('pros get Request canceled')
                }
            })
    }
}

export const getAbsences = (proid, start, end) => async dispatch => {
    const token = getFromSessionStorage('sessionToken')
    axios
        .get(`${ROOT_URL}/planning/get_absences?start=${start}&end=${end}&proid=${proid.toString()}`, {
            headers: { 'x-session': token }
        })
        .then(response => {
            dispatch({ payload: response.data, type: PLANNING })
        })
}

export const SendMissingPro = (id, obj) => async dispatch => {
    try {
        const token = getFromSessionStorage('sessionToken')
        axios
            .put(`${ROOT_URL}/planning/missing_pro/${id}`, obj, {
                headers: { 'x-session': token }
            })
            .then(response => {
                dispatch({ type: ABSENCE_PRO, payload: response.data })
            })
    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}

export const editMissingProAction = (id, obj) => async dispatch => {
    try {
        const response = await editMissingPro(id, obj)
        console.log(response)
        return dispatch({
            payload: response,
            type: ABSENCE_PRO
        })
    } catch (e) {
        console.log(e)
        throw new Error(e)
    }
}

export const sendCancelEvent = (id, data) => async dispatch => {
    const token = getFromSessionStorage('sessionToken')
    axios
        .put(`${ROOT_URL}/planning/cancel_event/${id}`, data, {
            headers: { 'x-session': token }
        })
        .then(response => {
            dispatch({ type: EVENT, payload: response.data })
        })
}

export function sendReminders(id) {
    const token = getFromSessionStorage('sessionToken')
    return function (dispatch) {
        axios.get(`${ROOT_URL}/planning/send_reminder/${id}`, {
            headers: { 'x-session': token }
        })
    }
}

export function updateRdvStatus(id, obj) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .put(`${ROOT_URL}/planning/event_honored/${id}`, obj, {
                headers: { 'x-session': token }
            })
            .then(response => {
                dispatch({ payload: response.data, type: EVENT })
            })
    }
}

export function editPlg(event) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .put(`${ROOT_URL}/planning/planning`, event, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: EVENT, payload: response.data })
            })
            .catch(err => {
                console.log('err', err)
            })
    }
}

export function getNextRdv(id, type, number) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
            ? getFromSessionStorage('sessionToken')
            : getCookie('sessionToken')
        axios
            .get(`${ROOT_URL}/planning/get_next_rdv/${id}/${type}/${number}`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                dispatch({ type: NEXTRDV, payload: response.data })
            })
    }
}

export function getLastRdv(id, type, number) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
            ? getFromSessionStorage('sessionToken')
            : getCookie('sessionToken')
        axios
            .get(`${ROOT_URL}/planning/get_last_rdv/${id}/${type}/${number}`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                dispatch({ payload: response.data, type: LASTRDV })
            })
    }
}

export function getStructurePlanning(proid = [], start, end, structures) {
    if (cancel !== undefined) {
        cancel()
    }
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .get(
                `${ROOT_URL}/planning/get_planning?start=${start}&end=${end}&proid=${JSON.stringify(
                    proid
                )}&structures=${JSON.stringify(structures)}`,
                {
                    headers: { 'x-session': token },
                    cancelToken: new CancelToken(c => {
                        cancel = c
                    })
                }
            )
            .then(response => {
                dispatch({ type: PLANNING, payload: response.data })
            })
            .catch(err => {
                console.log('ERROR', err)
                if (axios.isCancel(err)) {
                }
            })
    }
}

export function getOnePlanning(id, type, start, end) {
    if (cancel !== undefined) {
        cancel()
    }
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
            ? getFromSessionStorage('sessionToken')
            : getCookie('sessionToken')
        axios
            .get(`${ROOT_URL}/planning/get_one_planning?id=${id}&type=${type}&start=${start}&end=${end}`, {
                headers: { 'x-session': token },
                cancelToken: new CancelToken(c => {
                    cancel = c
                })
            })
            .then(response => {
                dispatch({ type: PLANNING, payload: response.data })
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                }
            })
    }
}

export function delPlg(id) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .delete(`${ROOT_URL}/planning/planning?id=${id}`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                dispatch({ type: EVENT, payload: response.data })
            })
            .catch(err => {
                console.log('err', err)
            })
    }
}

export function addPlg(obj) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')

        axios
            .post(`${ROOT_URL}/planning/planning`, obj, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: EVENT, payload: response.data })
            })
            .catch(err => {
                console.log('err', err)
            })
    }
}

export function getPlgPersonne(id, date, view) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .get(`${ROOT_URL}/planning/get_my_planning?date=${date}&id=${id}&view=${view}`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: PLANNING, payload: response.data })
            })
            .catch(err => {
                console.log('err', err)
            })
    }
}

export function getTown(date, structure) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')

        let _structure = []
        structure.map(function (item) {
            _structure.push(item.structureid)
        })

        axios
            .get(`${ROOT_URL}/planning/town?date=${date}&structure=${_structure.join(',')}`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: PLANNING, payload: response.data })
            })
            .catch(err => {
                console.log('err', err)
            })
    }
}

export function getAll(date, structure) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')

        let _structure = []
        structure.map(function (item) {
            _structure.push(item.structureid)
        })

        axios
            .get(`${ROOT_URL}/planning/get_all_planning?date=${date}&structure=${_structure.join(',')}`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: PLANNING_ALL, payload: response.data })
            })
            .catch(err => {
                console.log('err', err)
            })
    }
}

export function selectPro(pro) {
    return function (dispatch) {
        dispatch({ type: PRO_SELECTED, payload: pro })
    }
}
