import { getFromSessionStorage } from '../utils/manageSessionStorage'
import Axios from 'axios'
import config from '../config'
const rootUrlV2 = config.url_messaging_v2

/**
 *
 * @param {string} method
 * @param {string} url: without the first slash
 * @param {object} { query, body }: Query et Body
 * @param {object} headers: extra headers
 * @return {object, array, boolean} Data from backend
 */
export const fetchDataV2 = async (method, url, { query = {}, body = {} } = {}, headers = {}) => {
    const sessionToken = getFromSessionStorage('sessionToken')
    try {
        return await Axios({
            url,
            method,
            baseURL: `${rootUrlV2}/`,
            headers: { 'x-session': sessionToken, ...headers },
            params: query,
            data: body
        })
    } catch (e) {
        console.log(e)
        throw new Error(e)
    }
}

export const login = async data => await fetchDataV2('POST', 'user/web/connect', { body: data })

export const resetPasswordSendToIdentifier = async data =>
    await fetchDataV2('POST', 'user/web/forgot-password', { body: data })
export const resetPasswordSendCode = async data =>
    await fetchDataV2('POST', 'user/mobile/forgot-password-verify', { body: data })
export const resetPasswordSendPassword = async data =>
    await fetchDataV2('POST', 'user/mobile/forgot-password-update', { body: data })

export const updatePassword = async data => await fetchDataV2('PUT', 'user/web/update-password', { body: data })

export const register = async (firstname, lastname, identifier, password) =>
    await fetchDataV2('POST', 'user/web/register', { body: { identifier, firstname, lastname, password } })

export const uploadUserImagesProfile = async data =>
    await fetchDataV2(
        'POST',
        'user/mobile/upload-profile-picture',
        { body: data },
        { 'Content-Type': 'multipart/form-data' }
    )

export const sendRegisterCode = async (encodedPayload, code, device) =>
    await fetchDataV2('POST', 'user/mobile/signup-confirm', { body: { code, payload: encodedPayload, device } })

export const getBase = async () => await fetchDataV2('GET', 'user/mobile/get-base')

export const getMessages = async (conversationId, lastTimestamp = new Date().getTime()) =>
    await fetchDataV2('POST', 'conversation/mobile/fetch-messages', { body: { conversationId, lastTimestamp } })

export const getConversations = async () => await fetchDataV2('GET', 'conversation/web/conversations')

export const updateGroupInfo = async data =>
    await fetchDataV2('POST', 'conversation/mobile/group-update-infos', { body: data })

export const sendMessage = async data => await fetchDataV2('POST', 'conversation/mobile/send-message', { body: data })

export const sendPictures = async data =>
    fetchDataV2(
        'POST',
        'conversation/mobile/treat-pictures',
        { body: data },
        {
            'Content-Type': 'multipart/form-data'
        }
    )
export const sendFiles = async data =>
    fetchDataV2(
        'POST',
        'conversation/mobile/treat-files',
        { body: data },
        {
            'Content-Type': 'multipart/form-data'
        }
    )

export const removeParticipant = async data =>
    await fetchDataV2('POST', 'conversation/mobile/remove-participant', { body: data })

export const startCall = async conversationId =>
    await fetchDataV2('POST', 'conversation/mobile/start-call', { body: { conversationId } })

export const endCall = async conversationId =>
    await fetchDataV2('POST', 'conversation/mobile/end-call', { body: { conversationId } })

export const leaveOrDeleteConversation = async conversationId =>
    await fetchDataV2('POST', 'conversation/mobile/leave-or-delete', { body: { conversationId } })

export const searchContact = async search =>
    await fetchDataV2('GET', 'user/mobile/search-contacts', { query: { search } })

export const createGroupConversation = async obj =>
    await fetchDataV2('POST', 'conversation/mobile/create-group', { body: obj })

export const createOneToOneConversation = async obj =>
    await fetchDataV2('POST', 'conversation/mobile/create-simple', { body: obj })

export const sensitiveUpdateEmail = async email =>
    await fetchDataV2('POST', 'user/mobile/update-sensitive-email', { body: { email } })

export const editProfile = async obj => await fetchDataV2('POST', 'user/web/edit-profile', { body: obj })

export const validateUserEmailUpdate = async obj =>
    await fetchDataV2('POST', 'user/mobile/sensitive-validate', { body: obj })

export const getRecentConversation = async id => await fetchDataV2('GET', `conversation/web/recent-conversation/${id}`)

export const searchUsers = async str => await fetchDataV2('GET', `user/web/find-user/${str}`)

export const getUserById = async id => await fetchDataV2('GET', `user/web/find-user-by-id/${id}`)

export const getBeneficiaryGroups = async beneficiaryId =>
    await fetchDataV2('GET', `conversation/web/beneficiary-groups/${beneficiaryId}`)

export const getCompanyUsers = async companyId =>
    await fetchDataV2('GET', `user/web/get-all-company-users/${companyId}`)

export const addToMyCompany = async userId =>
    await fetchDataV2('PUT', 'user/web/add-user-company', { body: { userId } })

export const removeFromMyCompany = async userId =>
    await fetchDataV2('PUT', 'user/web/remove-user-company', { body: { userId } })

export const findUsersWithoutCompanyByName = async search =>
    await fetchDataV2('GET', `user/web/find-users-without-company-by-name/${search}`)

export const addParticipant = async (conversationId, arrayWithOneUser) =>
    await fetchDataV2('POST', 'conversation/mobile/add-participants', {
        body: { conversationId, contacts: arrayWithOneUser }
    })
