import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import Cookies from 'universal-cookie'

import * as actions from '../../store/actions/actions'
import { getFromSessionStorage } from '../../utils/manageSessionStorage'

const moment = require('moment')
const config = require('../../config')

const ROOT_URL = config.url_realtime //process.env.BASE_URL;

class BaseVideo extends React.Component {
    componentWillMount() {
        //const url = "http://localhost:3000/conference?convid=5e8c6d3bf0ecc11e24b722e8&access=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwiZmlyc3RuYW1lIjoiSm9obiIsImxhc3RuYW1lIjoiRG9lIiwiZ3Vlc3QiOnRydWUsInN0YXJ0IjoiMjAyMC0wNi0yNlQxNTowMDowMCIsImlhdCI6MTUxNjIzOTAyMn0.tCbUqV6zzMAb1h4a9P1By8caEyDR78qSqrmgqoV97l4";
        const convid = this.props.location.search.split('?convid=')[1]
        const token = this.props.location.search.split('&access=')[1]
        let current_time = moment().format('YYYY-MM-DDTHH:mm:ss')
        if (token) {
            var decoded = jwt.decode(token)
            const startC = decoded.start
            const endC = moment(startC).add(2, 'hours').format('YYYY-MM-DDTHH:mm:ss')
            if (current_time < startC || current_time > endC) {
                localStorage.setItem('startConference', startC, { expires: startC })
            } /* else if (current_time >= startC && current_time <= endC && decoded.guest == 'true') {
                // join the conference
            }*/
        }
        const cookies = new Cookies()
        if (cookies.get('sessionToken')) cookies.remove('sessionToken')
        if (!getFromSessionStorage('sessionToken') && getFromSessionStorage('_expired') === 'true') {
            window.location.href = '/login/?expired=true'
        } else if (!getFromSessionStorage('sessionToken')) {
            window.location.href = '/login'
        }
    }

    render() {
        return (
            <div className="" style={{ backgroundColor: '#000', height: '100%' }}>
                {this.props.children}
            </div>
        )
    }
}

BaseVideo.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    socket: PropTypes.object
}

const mapStateToProps = state => ({ auth: state.auth, socket: state.socket })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(BaseVideo)
