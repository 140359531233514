import axios from 'axios'

import { getFromSessionStorage } from '../../utils/manageSessionStorage'

export const TYPEEI = 'TYPEEI'
export const ADDEI = 'ADDEI'
export const LISTEI = 'LISTEI'
export const CURRENTEI = 'CURRENTEI'
export const UPDATEI = 'UPDATEI'
const config = require('../../config')

const ROOT_URL = config.url_gateway

export function listeitype() {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .get(`${ROOT_URL}/ei/type`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: TYPEEI, payload: response.data })
            })
            .catch(() => {})
    }
}

export function addeirequest(obj) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .put(`${ROOT_URL}/ei/event`, obj, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: ADDEI, payload: response.data })
            })
            .catch(() => {})
    }
}

export function listeirequest(uniqueid) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .get(`${ROOT_URL}/ei/event?uniqueid=${uniqueid}`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: LISTEI, payload: response.data })
            })
            .catch(() => {})
    }
}

export function setcurrentei(ei) {
    return function (dispatch) {
        dispatch({ type: CURRENTEI, payload: ei })
    }
}

export function updatecommentrequest(obj) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .post(`${ROOT_URL}/ei/event`, obj, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: UPDATEI, payload: response.data })
            })
            .catch(() => {})
    }
}
