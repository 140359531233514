import {
    ADD_PARTICIPANTS,
    DEFINE_NEW_LAST_MESSAGE,
    DELETE_CONVERSATION,
    receiveWsMessage,
    REMOVE_PARTICIPANTS,
    WS_EDIT_CONVERSATION,
    WS_NEW_CONVERSATION,
    WS_NEW_MESSAGE
} from '../../store/actions/messagingv2_actions'
import decodeMessagesFromBaseConversations from '../../utils/decodeMessagesFromBaseConversations'
import { CONVERSATION, DELETE_MESSAGE, EDIT_MESSAGE, LISTCONV } from '../../store/actions/omessage.actions'
import decodeMessagesFromMemoryConversations from '../../utils/decodeMessagesFromMemoryConversations'

export const SocketListeners = (ws, dispatch, messagingSelector) => {
    ws.on(ws.TYPES_RECEIVE.CONNECTION_ACCEPTED, () => {
        ws.onConnect()
    })

    ws.on(ws.TYPES_RECEIVE.PING, () => {
        ws.pong()
    })

    ws.on(ws.TYPES_RECEIVE.RECEIVE_MESSAGE, async data => {
        //dispatch(receiveWsMessage(data, messagingSelector.listconv))
        const { listconv: conversations, convid: currentConversationId } = messagingSelector
        try {
            const conversation = conversations.find(({ id }) => id === data.conversationId)
            const decodedMessage = decodeMessagesFromMemoryConversations([data], conversation)
            conversation.lastMessage = decodedMessage?.[0].dataText
            const conversationsFiltered = conversations.filter(({ id }) => id !== conversation.id)
            dispatch(dispatch => dispatch({ type: DEFINE_NEW_LAST_MESSAGE, payload: decodedMessage }))
            currentConversationId === conversation.id &&
                dispatch(dispatch => dispatch({ type: WS_NEW_MESSAGE, payload: decodedMessage }))
            return dispatch(dispatch => dispatch({ type: LISTCONV, payload: [conversation, ...conversationsFiltered] }))
        } catch (e) {
            throw new Error(e)
        }
    })

    ws.on(ws.TYPES_RECEIVE.NEW_CONVERSATION, async data => {
        try {
            const { listconv: conversations } = messagingSelector
            const isConvExist = conversations.find(({ id }) => data.id === id)
            return !isConvExist && dispatch(dispatch => dispatch({ type: WS_NEW_CONVERSATION, payload: data }))
        } catch (e) {
            throw new Error(e)
        }
    })

    ws.on(ws.TYPES_RECEIVE.CONVERSATION_REMOVE, async data => {
        try {
            return dispatch(dispatch => dispatch({ type: DELETE_CONVERSATION, payload: data }))
        } catch (e) {
            throw new Error(e)
        }
    })

    ws.on(ws.TYPES_RECEIVE.CONVERSATION_ADD_AFTER_UNCRYPT, async data => {
        const conversationsUncrypted = await decodeMessagesFromBaseConversations([data])
        try {
            return dispatch(dispatch => dispatch({ type: WS_NEW_CONVERSATION, payload: conversationsUncrypted[0] }))
        } catch (e) {
            throw new Error(e)
        }
    })

    ws.on(ws.TYPES_RECEIVE.DELETE_MESSAGE, async data => {
        try {
            return dispatch(dispatch => dispatch({ type: DELETE_MESSAGE, payload: data }))
        } catch (e) {
            throw new Error(e)
        }
    })

    ws.on(ws.TYPES_RECEIVE.CONVERSATION_REMOVE_PARTICIPANT, async data => {
        try {
            return dispatch(dispatch => dispatch({ type: REMOVE_PARTICIPANTS, payload: data }))
        } catch (e) {
            throw new Error(e)
        }
    })

    ws.on(ws.TYPES_RECEIVE.CONVERSATION_UPDATE_PARTICIPANTS, async data => {
        try {
            return dispatch(dispatch => dispatch({ type: ADD_PARTICIPANTS, payload: data }))
        } catch (e) {
            throw new Error(e)
        }
    })

    ws.on(ws.TYPES_RECEIVE.CONVERSATION_UPDATE_KEY_VALUE, async data => {
        try {
            return dispatch(dispatch => dispatch({ type: WS_EDIT_CONVERSATION, payload: data }))
        } catch (e) {
            throw new Error(e)
        }
    })
    ws.on(ws.TYPES_RECEIVE.UPDATE_MESSAGE_KEY_VALUE, async data => {
        try {
            const { convid: conversationId } = messagingSelector
            if (conversationId === data.conversationId) {
                return dispatch(dispatch => dispatch({ type: EDIT_MESSAGE, payload: data }))
            }
        } catch (e) {
            throw new Error(e)
        }
    })
    /*ws.on(ws.TYPES_RECEIVE.UPDATE_MULTIPLE_MESSAGES_KEY_VALUE, async data => {
        console.log('UPDATE_MULTIPLE_MESSAGE', data)
    })*/

    ws.on(ws.TYPES_RECEIVE.CONVERSATION_NEW_MESSAGE_SYSTEM, async data => {
        try {
            const { convid: conversationId, listconv: conversations } = messagingSelector
            const conversation = conversations.find(({ id }) => data.conversationId)
            conversation.lastMessage = data.message.dataSystem?.terminated ? 'Appel en cours' : 'Appel terminé'
            dispatch(dispatch => dispatch({ type: DEFINE_NEW_LAST_MESSAGE, payload: [data.message] }))
            if (conversationId === data.conversationId) {
                dispatch(dispatch => dispatch({ type: CONVERSATION, payload: conversation }))
                return dispatch(dispatch => dispatch({ type: WS_NEW_MESSAGE, payload: [data.message] }))
            }
        } catch (e) {
            throw new Error(e)
        }
    })

    ws.on(ws.TYPES_RECEIVE.CONVERSATION_CALL_INCOMING, async data => {})

    ws.on(ws.TYPES_RECEIVE.USER_UPDATE_KEYS_VALUES, async data => {})
    /*ws.on(ws.TYPES_RECEIVE.CONVERSATION_UPDATE_LEAVER_PARTICIPANTS, async data => {
        console.log(data)
    })*/
    /*ws.on(ws.TYPES_RECEIVE.USER_ONLINE, data => {
        Store.dispatch(userOnline(data))
    })
    ws.on(ws.TYPES_RECEIVE.USER_OFFLINE, data => {
        Store.dispatch(userOffline(data))
    })


    ws.on(ws.TYPES_RECEIVE.PARTICIPANT_UPDATE_KEYS_VALUES, async data => {
        Store.dispatch(participantUpdateKeysValues(data))
    })

    */
}
