import { getInjectedProperty } from '../../components/Pages/Iot/devicesStateHandlers'
import {
    GET_IOT_BUILDINGS,
    GET_IOT_PROPERTIES,
    GET_PROPERTY_STATES,
    IOT_STATS_PERIOD,
    IOT_STATS_ROOM,
    IOT_UPDATE,
    SET_IOT_PROPERTY,
    IOT_INCIDENTS_PERIOD,
    IOT_INCIDENTS_MODAL,
    IOT_INCIDENTS_ROOM,
    ADD_ROOM_PATIENT,
    REMOVE_ROOM_PATIENT
} from '../actions/iot.actions'

const initialState = {
    buildings: [],
    properties: [],
    currentProperty: {},
    stats: { period: 7, rooms: [] },
    incidents: { period: 7, rooms: [], modal: { isOpen: false, data: {} } }
}

const typeState = {
    contact: 'contact',
    motion: 'occupancy',
    pressure: 'water_leak',
    vibration: 'vibration'
}

const iotReducer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case GET_IOT_BUILDINGS:
            return { ...state, buildings: payload }

        case GET_IOT_PROPERTIES:
            return { ...state, properties: payload }

        case SET_IOT_PROPERTY:
            return { ...state, currentProperty: payload }

        case GET_PROPERTY_STATES: {
            return {
                ...state,
                properties: state.properties.map(currentProperty =>
                    currentProperty._id === payload._id ? payload : currentProperty
                )
            }
        }

        case IOT_UPDATE: {
            const { raspberry, device: deviceName, states: newStates } = payload
            return { ...state, currentProperty: getInjectedProperty(state.currentProperty, deviceName, newStates) }
            // return {
            //     ...state,
            //     properties: state.properties.map(property =>
            //         property.iotName !== raspberry ? property : getInjectedProperty(property, deviceName, newStates)
            //     )
            // }
        }

        case IOT_STATS_PERIOD:
            return { ...state, stats: { ...state.stats, period: payload } }

        case IOT_STATS_ROOM:
            return { ...state, stats: { ...state.stats, rooms: [payload] } }

        case IOT_INCIDENTS_PERIOD:
            return { ...state, incidents: { ...state.incidents, period: payload } }

        case IOT_INCIDENTS_ROOM:
            return { ...state, incidents: { ...state.incidents, rooms: payload } }

        case IOT_INCIDENTS_MODAL:
            return {
                ...state,
                incidents: { ...state.incidents, modal: { isOpen: !state.incidents.modal.isOpen, data: payload || {} } }
            }

        case ADD_ROOM_PATIENT: {
            const { roomId, newPatientInfos } = payload
            return {
                ...state,
                currentProperty: {
                    ...state.currentProperty,
                    rooms: state.currentProperty.rooms.map(room =>
                        room._id === roomId ? { ...room, roomPatients: [...room.roomPatients, newPatientInfos] } : room
                    )
                }
            }
        }

        case REMOVE_ROOM_PATIENT:
            const { roomId, removedRoomPatientId } = payload
            return {
                ...state,
                currentProperty: {
                    ...state.currentProperty,
                    rooms: state.currentProperty.rooms.map(room =>
                        room._id === roomId
                            ? {
                                  ...room,
                                  roomPatients: room.roomPatients.filter(
                                      roomPatient => roomPatient._id !== removedRoomPatientId
                                  )
                              }
                            : room
                    )
                }
            }

        default:
            return state
    }
}

export default iotReducer
