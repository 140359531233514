import axios from 'axios'
import Cookies from 'universal-cookie'

import { getFromSessionStorage, removeFromSessionStorage, setToSessionStorage } from '../../utils/manageSessionStorage'
import { getCookie, removeCookie, setCookie } from '../../utils/manageCookies'

const config = require('../../config')
const cookies = new Cookies()

export const UNAUTH_USER = 'UNAUTH_USER'
export const USER = 'USER'
export const UPDATEIMAGES = 'UPDATEIMAGES'
export const MAJPROFIL = 'MAJPROFIL'
export const USER_COMPAGNY = 'USER_COMPAGNY'
export const USERPROFIL = 'USERPROFIL'
export const CODE_SUCCESS = 'CODE_SUCCESS'
export const CODE_FAIL = 'CODE_FAIL'
export const ALL_USERS = 'ALL_USERS'
export const REGISTER_TOKEN = 'REGISTER_TOKEN'

const ROOT_URL = config.url_gateway

export const verifyRegisterToken = token => async dispatch => {
    axios
        .get(`${ROOT_URL}/auth/verify-register-token?token=${token}`)
        .then(response => {
            //Set Tie to expire
            dispatch({ type: REGISTER_TOKEN, payload: response.data })
        })
        .catch(() => {
            dispatch({ type: REGISTER_TOKEN, payload: 'Token incorrect' })
        })
}

export function checkUser() {
    return function (dispatch) {
        //const token = getFromSessionStorage('sessionToken')
        let token
        if (
            (getFromSessionStorage('sessionToken') === '' ||
                getFromSessionStorage('sessionToken') === undefined ||
                !getFromSessionStorage('sessionToken')) &&
            getCookie('sessionToken') !== '' &&
            getCookie('sessionToken') !== undefined
        ) {
            token = getCookie('sessionToken')
            setToSessionStorage('sessionToken', getCookie('sessionToken'))
            setToSessionStorage('_uid', getCookie('_uid'))
            setToSessionStorage('_email', getCookie('_email'))
            setToSessionStorage('_imageURL', getCookie('_imageURL'))
        } else {
            token = getFromSessionStorage('sessionToken')
        }
        axios
            .get(`${ROOT_URL}/auth/user`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                if (response?.status === 401) {
                    dispatch({ type: UNAUTH_USER })
                } else {
                    const user = response?.data
                    const company = user?.compagny ? user.compagny : ''
                    if (company)
                        axios
                            .get(`${ROOT_URL}/core/company/${company}`, {
                                headers: { 'x-session': token }
                            })
                            .then(response => {
                                return dispatch({
                                    type: USER,
                                    payload: {
                                        ...user,
                                        companyName: response?.data.nom,
                                        companySiren: response?.data.siren
                                    }
                                })
                            })
                            .catch(() => {})
                    dispatch({ type: USER, payload: user })
                }
            })
            .catch(error => {
                dispatch({ type: UNAUTH_USER })
            })
    }
}

export function signin(mobile, password) {
    return function (dispatch) {
        axios
            .post(`${ROOT_URL}/auth/signin`, { email: mobile, password: password })
            .then(response => {
                const user = response.data

                setToSessionStorage('sessionToken', user.token)
                setToSessionStorage('_uid', user.userid)
                setToSessionStorage('_email', user.mobile)
                setToSessionStorage('_imageURL', user.imageURL)

                dispatch({ type: USER, payload: response.data })
            })
            .catch(error => {
                dispatch({ type: UNAUTH_USER })
            })
    }
}

export const setSessionExpiredAction = () => dispatch => {
    setToSessionStorage('_expired', true)
    dispatch(logout())
}

export function recoverPassword(mobile) {
    return function (dispatch) {
        setToSessionStorage('_email', mobile)

        axios
            .post(`${ROOT_URL}/auth/receivetoken`, { mobile: mobile })
            .then(response => {
                //Set Tie to expire

                console.log(response.data)

                dispatch({ type: USER, payload: response.data })
            })
            .catch(error => {
                dispatch({ type: UNAUTH_USER })
            })
    }
}

export const resetPassword = newpassword => async dispatch => {
    const mobile = getFromSessionStorage('_email')
    const validtoken = getFromSessionStorage('_validCode')

    try {
        await axios.post(`${ROOT_URL}/auth/resetpassword`, { mobile, newpassword, validtoken })
    } catch (error) {
        console.warn('Error when resetting password')
        throw new Error(error.message)
    }
}

export function signup(name, surname, mobile, password) {
    return async function (dispatch) {
        let _structure = localStorage.getItem('registertoken')
        axios
            .put(`${ROOT_URL}/auth/signup`, {
                mobile: mobile,
                password: password,
                name: name,
                surname: surname,
                structure: _structure
            })
            .then(response => {
                if (response) {
                    setToSessionStorage('_uid', response.data.userid)
                    setToSessionStorage('_email', response.data.mobile)
                    setToSessionStorage('_imageURL', response.data.imageURL)

                    dispatch({ type: USER, payload: response.data })
                }
            })
            .catch(error => {
                if (error.response.status === 422) {
                    dispatch({ type: USER, payload: { error: 'already registered' } })
                } else {
                    dispatch({ type: UNAUTH_USER })
                }
            })
    }
}

export function signupMobile(name, surname, mobile, password) {
    return async function (dispatch) {
        let _structure = localStorage.getItem('registertoken')
        axios
            .put(`${ROOT_URL}/auth/signup_mobile`, {
                mobile: mobile,
                password: password,
                name: name,
                surname: surname,
                structure: _structure
            })
            .then((response, err) => {
                if (response) {
                    setToSessionStorage('_uid', response.data.userid)
                    setToSessionStorage('_email', response.data.mobile)
                    setToSessionStorage('_imageURL', response.data.imageURL)

                    dispatch({ type: USER, payload: response.data })
                } else {
                    dispatch({ type: UNAUTH_USER })
                }
            })
            .catch(error => {
                if (error.response?.status === 422) {
                    dispatch({ type: USER, payload: { error: 'already registered' } })
                } else {
                    dispatch({ type: UNAUTH_USER })
                }
            })
    }
}

export const saveToken = code => async dispatch => {
    const validToken = code
    const email = getFromSessionStorage('_email')

    try {
        await axios.get(`${ROOT_URL}/auth/token/verify?token=${validToken}&email=${email}`)
        setToSessionStorage('_validCode', validToken)
    } catch (error) {
        console.warn('Error in token validation', error)
        throw new Error(error.message)
    }
}

export function validaccount(code1) {
    return function (dispatch) {
        const validtoken = code1
        const userid = getFromSessionStorage('_uid')

        axios
            .post(`${ROOT_URL}/auth/activeaccount`, { validtoken: validtoken })
            .then(response => {
                setToSessionStorage('sessionToken', response.data.token)
                setToSessionStorage('_uid', response.data.userid)
                setToSessionStorage('_email', response.data.mobile)
                setToSessionStorage('_imageURL', response.data.imageURL)

                dispatch({ type: USER, payload: response.data })
            })
            .catch(error => {
                dispatch({ type: UNAUTH_USER })
            })
    }
}

export function logout() {
    return function (dispatch) {
        removeFromSessionStorage('sessionToken')
        removeFromSessionStorage('_uid')
        removeFromSessionStorage('_email')
        removeFromSessionStorage('_imageURL')
        dispatch({ type: UNAUTH_USER, payload: null })
    }
}

export function lock() {
    return function (dispatch) {
        removeFromSessionStorage('_auth')
        dispatch({ type: UNAUTH_USER })
    }
}

export const updateUserProfile = obj => async (dispatch, getState) => {
    const token = getFromSessionStorage('sessionToken')
    const {
        auth: { payload: currentUserInfo }
    } = getState()

    try {
        const response = await axios.put(`${ROOT_URL}/auth/user`, obj, {
            headers: { 'x-session': token }
        })
        const user = response.data
        dispatch({ type: USER, payload: { ...currentUserInfo, ...user } })
    } catch (error) {
        console.log(error)
        throw new Error('Une erreur est survenue')
    }
}

export const updatePassword = obj => async dispatch => {
    const token = getFromSessionStorage('sessionToken')

    try {
        const response = await axios.post(`${ROOT_URL}/auth/password`, obj, {
            headers: { 'x-session': token }
        })
        const user = response.data
        const { compagny: company } = user
        if (company) {
            const resp = await axios.get(`${ROOT_URL}/core/company/${company}`, {
                headers: { 'x-session': token }
            })
            return dispatch({
                type: USER,
                payload: {
                    ...user,
                    companyName: resp.data.nom,
                    companySiren: resp.data.siren
                }
            })
        }
        return dispatch({ type: USER, payload: user })
    } catch (error) {
        console.log(error)
        throw new Error('Une erreur est survenue')
    }
}

export const updateUserImageAction = file => async (dispatch, getState) => {
    const token = getFromSessionStorage('sessionToken')
    const {
        auth: { payload: currentUserInfo }
    } = getState()

    try {
        const response = await axios.put(
            `${ROOT_URL}/auth/updateimageurl`,
            { file },
            {
                headers: { 'x-session': token }
            }
        )
        const user = response.data
        dispatch({
            type: USER,
            payload: {
                ...currentUserInfo,
                ...user
            }
        })
    } catch (error) {
        console.log(error)
        throw new Error('Une erreur est survenue')
    }
}

export function getuserprofil(userid) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .get(`${ROOT_URL}/auth/user?uniqueid=${userid}`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                dispatch({ type: USERPROFIL, payload: response.data })
            })
            .catch(() => {})
    }
}

export function getAllUsers() {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .get(`${ROOT_URL}/auth/findalluser`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                dispatch({ type: ALL_USERS, payload: response.data })
            })
            .catch(() => {})
    }
}
