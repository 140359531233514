import {
    LIST_PERSON,
    PERSON,
    RESET_PERSON,
    TIMESLOTS,
    GET_PERSON_NOTIFICATIONS,
    GET_PERSON_DISCUSSIONS,
    GET_PRO_STRUCTURES,
    CREATE_PERSON_DISCUSSION,

} from '../actions/actions'

const initialState = {
    listperson: [],
    person: '',
    discussions: [],
    notifications: [],
    proStructures: []
}

const personReducer = (state = initialState, action) => {
    switch (action.type) {
        case TIMESLOTS:
            return {
                ...state,
                person: action.payload
            }
        case LIST_PERSON:
            return {
                ...state,
                listperson: action.payload
            }
        case PERSON:
            return {
                ...state,
                person: action.payload
            }
        case RESET_PERSON:
            return {
                ...state,
                person: '',
                discussions: [],
                notifications: [],
                proStructures: []
            }
        case GET_PERSON_DISCUSSIONS:
            return {
                ...state,
                discussions: action.payload
            }
        case CREATE_PERSON_DISCUSSION:
            return {
                ...state,
                discussions: [action.payload, ...state.discussions]
            }
        case GET_PERSON_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            }
        case GET_PRO_STRUCTURES:
            return {
                ...state,
                proStructures: action.payload
            }
        default:
            return state
    }
}

export default personReducer
