import React, { useEffect } from 'react'
import { SocketListeners } from './socketListeners'
import { getFromSessionStorage } from '../../utils/manageSessionStorage'
import config from '../../config'
import { useDispatch, useSelector } from 'react-redux'

const TYPES_SEND = {
    PONG: 'pong',
    ON_CONNECT: 'onConnect',
    ON_VIEW_CONVERSATION: 'onViewConversation',
    ON_VIEW_MESSAGES: 'onViewMessages'
}
const TYPES_RECEIVE = {
    PING: 'ping',
    CONNECTION_ACCEPTED: 'connectionAccepted',
    USER_ONLINE: 'userOnline',
    USER_OFFLINE: 'userOffline',
    USER_UPDATE_KEYS_VALUES: 'userUpdateKeysValues',
    PARTICIPANT_UPDATE_KEYS_VALUES: 'participantUpdateKeysValues',
    RECEIVE_MESSAGE: 'receiveMessage',
    DELETE_MESSAGE: 'deleteMessage',
    UPDATE_MESSAGE_KEY_VALUE: 'updateMessageKeyValue',
    UPDATE_MULTIPLE_MESSAGES_KEY_VALUE: 'updateMultipleMessagesKeyValue',
    NEW_CONVERSATION: 'newConversation',
    CONVERSATION_UPDATE_KEY_VALUE: 'conversationUpdateKeyValue',
    CONVERSATION_REMOVE_PARTICIPANT: 'conversationRemoveParticipant',
    CONVERSATION_UPDATE_PARTICIPANTS: 'conversationUpdateParticipants',
    CONVERSATION_UPDATE_LEAVER_PARTICIPANTS: 'conversationUpdateLeaverParticipants',
    CONVERSATION_REMOVE: 'conversationRemove',
    CONVERSATION_ADD_AFTER_UNCRYPT: 'conversationAddAfterUncrypt',
    CONVERSATION_CALL_INCOMING: 'conversationCallIncoming',
    CONVERSATION_NEW_MESSAGE_SYSTEM: 'conversationNewMessageSystem'
}

let ws = null
let reconnect = true
let timeReconnect = 1000
let intervalReconnect = null
let timeoutReconnect = null

export default {
    /***************
     * BASE STRUCTURE
     *****************/
    memoryPath: null,
    methods: {},
    TYPES_RECEIVE: TYPES_RECEIVE,

    Connect() {
        const dispatch = useDispatch()
        const messagingSelector = useSelector(({ omessage }) => omessage)
        reconnect = true
        let _this = this

        this.disconnect = function () {
            reconnect = false
            if (ws) ws.close()
        }

        const build = () => {
            clearTimeout(timeoutReconnect)
            timeoutReconnect = setTimeout(function () {
                build()
            }, 1500)
            let websocket = new WebSocket(config.ws_messaging_v2 + '/' + getFromSessionStorage('sessionToken'))
            websocket.onopen = () => {
                clearTimeout(timeoutReconnect)
                // setReconnect(true);
                ws = websocket
                websocket.onmessage = event => {
                    let data
                    try {
                        data = JSON.parse(event.data)
                    } catch {}
                    let type = data.type
                    if (_this.methods[type]) _this.methods[type](data.data)
                }

                websocket.onclose = () => {
                    if (reconnect) {
                        clearTimeout(timeoutReconnect)
                        timeoutReconnect = setTimeout(function () {
                            build()
                        }, 1500)
                        // intervalReconnect = setInterval(() => {
                        //     build();
                        // }, timeReconnect)
                    } else {
                        reconnect = true
                    }
                }

                SocketListeners(_this, dispatch, messagingSelector)
            }
        }

        useEffect(() => {
            build()
        }, [])
    },
    on(name, method) {
        this.methods[name] = method
    },
    _send(data) {
        try {
            ws.send(JSON.stringify(data))
        } catch (e) {}
    },
    /***************
     * LAYER STRUCTURE
     *****************/

    onConnect() {
        this._send({
            type: TYPES_SEND.ON_CONNECT
        })
    },
    pong() {
        this._send({
            type: TYPES_SEND.PONG
        })
    },
    onViewConversation(data) {
        this._send({
            type: TYPES_SEND.ON_VIEW_CONVERSATION,
            data: data
        })
    },
    onViewMessages(data) {
        this._send({
            type: TYPES_SEND.ON_VIEW_MESSAGES,
            data: data
        })
    }
    // onViewConversation(data) {
    //     console.log('SEND ON VIEW CONVERSATION = '+data);
    //     this._send({
    //         type: TYPES_SEND.ON_VIEW_CONVERSATION,
    //         data: {
    //             conversationId: data
    //         }
    //     })
    // }
}
