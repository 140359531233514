import { SEARCH_RESULT, SEARCH_RESULT_PROS, RESET_SEARCH_RESULT } from '../actions/search.actions'

const initialState = {
    all: [],
    professionals: [],
    beneficiaries: []
}

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_RESULT:
            const {
                searchResultAll: all,
                searchResultProfessionals: professionals,
                searchResultBeneficiaries: beneficiaries
            } = action.payload
            return { all, professionals, beneficiaries }
        case SEARCH_RESULT_PROS:
            return { ...state, professionals: action.payload }
        case RESET_SEARCH_RESULT:
            return { all: [], professionals: [], beneficiaries: [] }
        default:
            return state
    }
}

export default searchReducer
