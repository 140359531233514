import PropTypes from 'prop-types'
import React from 'react'

import Bubble from './Bubble'

const InitialsBubble = ({ bubbleSize, className, firstName, fontSize, lastName, pro, borderStrong, ...props }) => (
    <Bubble
        className={className}
        background="#EBF4F5"
        border={pro ? '#F8AD80' : '#2E3F5E'}
        borderStrong={borderStrong}
        size={bubbleSize}
        {...props}
    >
        {(firstName || lastName) && (
            <span style={{ color: '#8cbcac', fontSize, fontWeight: 'bold' }}>
                {firstName?.slice(0, 1).toUpperCase()}
                {lastName?.slice(0, 1).toUpperCase()}
            </span>
        )}
    </Bubble>
)

InitialsBubble.propTypes = {
    borderStrong: PropTypes.bool,
    bubbleSize: PropTypes.number,
    className: PropTypes.string,
    firstName: PropTypes.string,
    fontSize: PropTypes.number,
    lastName: PropTypes.string,
    pro: PropTypes.bool
}

InitialsBubble.defaultProps = {
    borderStrong: true,
    bubbleSize: null,
    className: '',
    firstName: '',
    lastName: '',
    fontSize: 22,
    pro: false
}

export default InitialsBubble
