import {
    getIotBuildings,
    getIotProperties,
    getPropertyStates,
    handleNewRoomPatient,
    handleRemoveRoomPatient,
    getPingFromMs
} from '../../api/iot'

export const GET_IOT_BUILDINGS = 'GET_IOT_BUILDINGS'
export const GET_IOT_PROPERTIES = 'GET_IOT_PROPERTIES'
export const GET_PROPERTY_STATES = 'GET_PROPERTY_STATES'

export const SET_IOT_PROPERTY = 'SET_IOT_PROPERTY'

export const IOT_UPDATE = 'IOT_UPDATE'

export const IOT_STATS_PERIOD = 'IOT_STATS_PERIOD'
export const IOT_STATS_ROOM = 'IOT_STATS_ROOM'

export const IOT_INCIDENTS_PERIOD = 'IOT_INCIDENT_PERIOD'
export const IOT_INCIDENTS_ROOM = 'IOT_INCIDENTS_ROOM'
export const IOT_INCIDENTS_MODAL = 'IOT_INCIDENTS_MODAL'

export const ADD_ROOM_PATIENT = 'ADD_ROOM_PATIENT'
export const REMOVE_ROOM_PATIENT = 'REMOVE_ROOM_PATIENT'

export const getPingFromMsAction = () => async dispatch => {
    try {
        const { data: response } = await getPingFromMs()
        return dispatch({
            type: GET_IOT_BUILDINGS,
            payload: response.data
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {string} structureId
 */

export const getIotBuildingsAction = structureId => async dispatch => {
    try {
        const { data: response } = await getIotBuildings(structureId)
        return dispatch({
            type: GET_IOT_BUILDINGS,
            payload: response.data
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {string} structureId
 */

export const getIotPropertiesAction = () => async dispatch => {
    try {
        const { data: response } = await getIotProperties()
        return dispatch({
            type: GET_IOT_PROPERTIES,
            payload: response.data
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {string} propertyId
 */

export const getIotPropertySatesAction = propertyId => async dispatch => {
    try {
        const { data: response } = await getPropertyStates(propertyId)
        return dispatch({
            type: GET_PROPERTY_STATES,
            payload: response.data
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {object} currentProperty
 */

export const setIotCurrentProperty = currentProperty => dispatch => {
    try {
        return dispatch({
            type: SET_IOT_PROPERTY,
            payload: currentProperty
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {object} iotUpdate
 */

export const updateDeviceStateAction = iotUpdate => dispatch => {
    try {
        return dispatch({
            type: IOT_UPDATE,
            payload: iotUpdate
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {number} nbDays
 */

export const changeIotStatsPeriod = nbDays => dispatch => {
    try {
        return dispatch({
            type: IOT_STATS_PERIOD,
            payload: nbDays
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {string} roomId
 */

export const changeIotStatsRoom = roomId => dispatch => {
    try {
        return dispatch({
            type: IOT_STATS_ROOM,
            payload: roomId
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {number} nbDays
 */

export const changeIotIncidentsPeriod = nbDays => dispatch => {
    try {
        return dispatch({
            type: IOT_INCIDENTS_PERIOD,
            payload: nbDays
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {string} roomId
 */

export const changeIotIncidentsRoom = incidentsRooms => dispatch => {
    try {
        return dispatch({
            type: IOT_INCIDENTS_ROOM,
            payload: incidentsRooms
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {Object} incidentData
 */
export const toggleIotIncidentModal = incidentData => dispatch => {
    try {
        return dispatch({
            type: IOT_INCIDENTS_MODAL,
            payload: incidentData
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {string} room _id
 * @param {object} patientInfos { _id, nom, prenom }
 * @returns
 */

export const saveRoomPatient = (roomId, patientInfos) => async dispatch => {
    try {
        await handleNewRoomPatient(roomId, patientInfos._id)
        return dispatch({
            type: ADD_ROOM_PATIENT,
            payload: { roomId, newPatientInfos: { patient: patientInfos } }
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {string} roomPatient _id
 * @param {object} [update] { leftOn: Date }
 * @returns
 */

export const removeRoomPatient = (roomPatient, update) => async dispatch => {
    try {
        const { data: response } = await handleRemoveRoomPatient(roomPatient, update)
        const { data: resData } = response
        return dispatch({
            type: REMOVE_ROOM_PATIENT,
            payload: { roomId: resData.roomId, removedRoomPatientId: roomPatient }
        })
    } catch (err) {
        console.log(err)
    }
}
