/**
 *
 * @param {string} itemName
 */
export const getFromSessionStorage = itemName => {
    return sessionStorage.getItem(itemName)
}

/**
 *
 * @param {string} itemName
 * @param {*} itemValue
 */
export const setToSessionStorage = (itemName, itemValue) => {
    return sessionStorage.setItem(itemName, itemValue)
}

/**
 *
 * @param {string} itemName
 */
export const removeFromSessionStorage = itemName => {
    return sessionStorage.removeItem(itemName)
}
