import { ADD_NOTIFICATION, GET_USER_NOTIFICATIONS, MARK_NOTIFICATIONS_SEEN } from '../actions/notifications_actions'

const initialState = {
    list: [],
    hasMore: true
}

const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NOTIFICATION:
            return {
                ...state,
                list: [action.payload, ...state.list].filter((notification,index,array)=>array.findIndex(n=>(n._id === notification._id))===index)

            }
        case GET_USER_NOTIFICATIONS:
            return {
                list: [...state.list, ...action.payload].filter((notification,index,array)=>array.findIndex(n=>(n._id === notification._id))===index),
                hasMore: action.payload.length >= 15
            }
        case MARK_NOTIFICATIONS_SEEN:
            const { notificationsIds, userId } = action.payload
            return {
                ...state,
                list: state.list?.map(notification =>
                    notificationsIds.includes(notification._id)
                        ? { ...notification, seen: [...notification.seen, userId] }
                        : notification
                ).filter((notification,index,array)=>array.findIndex(n=>(n._id === notification._id))===index),
            }
        default:
            return state
    }
}

export default notificationsReducer
