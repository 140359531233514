import axios from 'axios'

import { getFromSessionStorage } from '../../utils/manageSessionStorage'

export const STATPERSON = 'STATPERSON'
export const STATAGGIR = 'STATAGGIR'

const config = require('../../config')

const ROOT_URL = config.url_gateway

export function globalstat(value) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .get(`${ROOT_URL}/person/stat?periode_start=2019-01-01&periode_end=2019-01-31`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: STATPERSON, payload: response.data })
            })
            .catch(() => {})
    }
}

export function getAggirStats(value) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .get(`${ROOT_URL}/person/stat/aggir?date=${value}`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: STATAGGIR, payload: response.data })
            })
            .catch(err => {
                console.log('err', err)
            })
    }
}
