import { CONTROLIDENTITO } from '../actions/actions'

const initialState = {
    controlidentito: []
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONTROLIDENTITO:
            return {
                ...state,
                controlidentito: action.payload
            }

        default:
            return state
    }
}

export default authReducer
