import axios from 'axios'

import config from '../config'
import { getFromSessionStorage } from '../utils/manageSessionStorage'

const ROOT_URL = config.url_gateway

/**
 *
 * @param {string} path
 * @param {string} error
 */

export const trackError = (path, error) => {
    const token = getFromSessionStorage('sessionToken')
    return axios.post(`${ROOT_URL}/core/errors`, { error, path }, { headers: { 'x-session': token } })
}
