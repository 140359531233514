import { TYPEEI, ADDEI, LISTEI, CURRENTEI, UPDATEI } from '../actions/actions'

const stat = store => next => action => {
    let result = next(action)

    if (action.type === TYPEEI) {
        return { auth: true, typeei: action.payload }
    }

    if (action.type === ADDEI) {
        return { auth: true, addei: action.payload }
    }

    if (action.type === LISTEI) {
        return { auth: true, listei: action.payload }
    }

    if (action.type === CURRENTEI) {
        return { auth: true, currentei: action.payload }
    }

    if (action.type === UPDATEI) {
        return { auth: true, updatei: action.payload }
    }

    return result
}

export default stat
