import { getFromSessionStorage } from './manageSessionStorage'
import Axios from 'axios'
const ROOT_URL = require('../config').url_gateway

/**
 *
 * @param {string} method
 * @param {string} url: without the first slash
 * @param {object} {query, body}: Query et Body
 * @param {object} headers: extra headers
 * @return {object, array, boolean} Data from backend
 */
export const fetchData = async (method, url, { query = {}, body = {} } = {}, headers = {}) => {
    const token = getFromSessionStorage('sessionToken')
    try {
        const { data } = await Axios({
            url,
            method,
            baseURL: `${ROOT_URL}/`,
            headers: { ...headers, 'x-session': token },
            params: query,
            data: method !== 'get' && method !== 'delete' && body
        })
        return data
    } catch (e) {
        console.log(e)
        throw new Error(e)
    }
}
