import Crypter from './crypter'

export default function decodeMessagesFromMemoryConversations(messages, conversation) {
    for (const message of messages) {
        try {
            if (message.encoded && !message.deleted) {
                try {
                    if (message.dataText) {
                        message.dataText = Crypter.AES.CBC.DECODE(conversation.masterkey, message.dataText)
                    }
                    if (message.dataPictures) {
                        message.dataPictures = JSON.parse(
                            Crypter.AES.CBC.DECODE(conversation.masterkey, message.dataPictures)
                        )
                    }
                    if (message.dataFiles) {
                        const decrypted = Crypter.AES.CBC.DECODE(conversation.masterkey, message.dataFiles)
                        message.dataFiles = typeof message.dataFiles === 'string' ? decrypted : JSON.parse(decrypted)
                    }
                } catch (e) {}
                message.encoded = false
                if (message.replyAt && !message.replyAt.deleted) {
                    if (message.replyAt.encoded) {
                        if (message.replyAt.dataText) {
                            message.replyAt.dataText = Crypter.AES.CBC.DECODE(
                                conversation.masterkey,
                                message.replyAt.dataText
                            )
                        }
                        if (message.replyAt.dataPictures) {
                            message.replyAt.dataPictures = JSON.parse(
                                Crypter.AES.CBC.DECODE(conversation.masterkey, message.replyAt.dataPictures)
                            )
                        }
                        if (message.replyAt.dataFiles) {
                            const decrypted = Crypter.AES.CBC.DECODE(conversation.masterkey, message.dataFiles)
                            message.replyAt.dataFiles =
                                typeof decrypted === 'string' ? decrypted : JSON.parse(decrypted)
                        }
                        message.replyAt.encoded = false
                    }
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    return messages
}
