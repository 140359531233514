import axios from 'axios'

import config from '../config'
import { getFromSessionStorage } from '../utils/manageSessionStorage'

const ROOT_URL = config.url_realtime

/**
 *
 * @param {string} userId
 * @param {number} pageNumber
 */

export const getUserNotifications = async (userId, pageNumber) => {
    const token = getFromSessionStorage('sessionToken')

    return axios.get(`${ROOT_URL}/v1/notifications?user=${userId}&page=${pageNumber}`, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {string} beneficiaryId
 */

export const getBeneficiaryNotifications = async beneficiaryId => {
    const token = getFromSessionStorage('sessionToken')

    return axios.get(`${ROOT_URL}/v1/notifications/beneficiary/${beneficiaryId}`, {
        headers: { 'x-session': token }
    })
}
