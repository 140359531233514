import {
    USER_COMPAGNY,
    USER,
    UNAUTH_USER,
    UPDATEIMAGES,
    MAJPROFIL,
    USERPROFIL,
    CODE_SUCCESS,
    CODE_FAIL,
    ALL_USERS,
    CURRENT_COMPANY,
    REGISTER_TOKEN,
    UPDATE_USER_BASE,
    REMOVE_USER_FROM_ALL_USERS_COMPANY,
    ADD_USER_TO_ALL_USERS_COMPANY
} from '../actions/actions'

const initialState = {
    payload: '',
    updateimage: false,
    updateprofile: false,
    user_compagny: false,
    all_users: [],
    current_company: '',
    registerToken: null
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_TOKEN:
            return {
                registerToken: action.payload
            }
        case USER_COMPAGNY:
            return {
                ...state,
                user_compagny: action.payload
            }

        case USER:
            return {
                ...state,
                payload: action.payload
            }
        case UPDATE_USER_BASE:
            return {
                ...state,
                payload: {
                    ...state.payload,
                    ...action.payload
                }
            }

        case MAJPROFIL:
            return {
                ...state,
                updateprofile: action.payload
            }

        case UPDATEIMAGES:
            return {
                ...state,
                payload: {
                    ...state.payload,
                    images: action.payload
                }
            }

        case UNAUTH_USER:
            return {
                payload: action.payload
            }
        case USERPROFIL:
            return {
                ...state,
                payload: action.payload
            }
        case CODE_SUCCESS:
            return {
                ...state,
                status: 'success',
                payload: action.payload
            }
        case CODE_FAIL:
            return {
                status: 'error',
                payload: action.payload
            }

        case ALL_USERS:
            return {
                ...state,
                allUsers: action.payload
            }
        case REMOVE_USER_FROM_ALL_USERS_COMPANY:
            return {
                ...state,
                allUsers: state.allUsers.filter(({ _id }) => _id !== action.payload)
            }
        case ADD_USER_TO_ALL_USERS_COMPANY:
            return {
                ...state,
                allUsers: [...state.allUsers, action.payload]
            }
        default:
            return state
    }
}

export default authReducer
