const STATE_KEY = 'roles'

export const selectRoles = state => state[STATE_KEY]

/**
 *
 * @param {object} state
 * @param {string} roleId
 */

export const selectRoleMembers = (state, roleId) => {
    const roles = selectRoles(state)
    const role = roles.find(({ _id }) => _id === roleId)
    return role.members
}

export const selectRolesByStructureId = (state, structureId) => {
    const roles = selectRoles(state)
    return roles.filter(({ structure }) => structure === structureId)
}
