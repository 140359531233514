import classnames from 'classnames'
import React from 'react'
import PropTypes, { oneOfType } from 'prop-types'

import './Button.scss'

const CustomButton = ({ children, className, color, width, ...props }) => {
    return (
        <button
            className={classnames('CustomButton py-1', color, className)}
            style={{ width: `${width}px`, margin: 5 }}
            type="button"
            {...props}
        >
            {children}
        </button>
    )
}

CustomButton.propTypes = {
    children: oneOfType([PropTypes.node, PropTypes.string]),
    className: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.number
}

CustomButton.defaultProps = {
    children: null,
    className: '',
    color: 'custom_green',
    width: 75
}

export const ClearButton = ({ label, className,  ...props }) => {
    return (
        <button
            className={classnames('ClearButton py-1', className)}
            type="button"
            {...props}
        >
            {label}
        </button>
    )
}

export default CustomButton
