import React, { useEffect } from 'react'

import CustomButton from '../Button/Button'

import { trackErrorAction } from '../../store/actions/error.actions'
import { errorStatusCodes } from '../../utils/errorsStatusCodes'

import './Error.scss'

const ErrorView = ({ location: { state } }) => {
    const errorCode = state?.statusCode || 403
    const errorMessage = errorStatusCodes[errorCode]

    const redirectToHomePage = () => {
        window.location.href = '/search'
    }

    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'production') {
            if (state?.location?.pathname) trackErrorAction(state.location.pathname, state.error)
        }
    }, [state])

    return (
        <div className="d-flex vh-100 align-items-center flex-wrap justify-content-around">
            <div className="ErrorView__text-container h-100 d-flex justify-content-center col-12 col-lg-6">
                <div className="d-flex flex-column h-100 pl-5">
                    {errorCode && <p className="ErrorView__text-container-error w-100">ERROR {errorCode}</p>}
                    <p className="ErrorView__text-container-oups w-100">Oups !</p>
                    <p className="ErrorView__text-container-message w-100">{errorMessage}</p>
                    <CustomButton className="py-2" width={175} onClick={redirectToHomePage}>
                        Retour à la page d'acceuil
                    </CustomButton>
                </div>
            </div>
            <div className="ErrorView__image-container col-12 col-lg-6">
                <img className="ErrorView__image" src="/img/error_page.png" alt="error" />
            </div>
        </div>
    )
}

export default ErrorView
