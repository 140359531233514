import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { getFromSessionStorage } from '../../utils/manageSessionStorage'

import './Menu.scss'
import { setCookie } from '../../utils/manageCookies'
import { Link } from 'react-router-dom'
import { getLogoSrc } from '../TogiSante/getLogo'
import { freeConversation, freeMessages } from '../../store/actions/omessage.actions'

const SIREN_ACODEGE = '333695922'
const SIREN_TOGI = '534061528'
const SIREN_IOT_STG_TEST = '123456789'

const Menu = ({ isOpen, toggle }) => {
    const history = useHistory()
    const dispatch = useDispatch()

    const { payload: User } = useSelector(state => state.auth)

    const isFromAcodege = User?.companySiren === SIREN_ACODEGE

    const currentEnv = process.env.REACT_APP_ENV
    const userSiren = User?.companySiren
    const isIotUser = currentEnv === 'production' ? userSiren === SIREN_TOGI : userSiren === SIREN_IOT_STG_TEST

    const handleOpenContextMenu = () => {
        const token = getFromSessionStorage('sessionToken')
        const userId = getFromSessionStorage('_uid')
        setCookie('sessionToken', token, { path: '/', maxAge: 30 })
        setCookie('_uid', userId, { path: '/', maxAge: 30 })
    }

    const redirectTo = path => {
        toggle()
        switch (path) {
            // TODO: Do not delete in case we want to restore the messaging in a new tab option
            // case 'messenger':
            //     const messengerUrl = `/messenger`
            //     const token = getFromSessionStorage('sessionToken')
            //     const userId = getFromSessionStorage('_uid')
            //     const cookies = new Cookies()
            //     cookies.set('sessionToken', token, { maxAge: 30 })
            //     cookies.set('_uid', userId, { maxAge: 30 })
            //     return window.open(messengerUrl)
            case 'messenger':
                dispatch(freeMessages())
                dispatch(freeConversation())
                return history.push('/messenger')
            case 'folder':
                return history.push('/search')
            case 'param':
                return history.push('/admin/settings')
            case 'list_person':
                return history.push('/admin/list_person')
            // case 'planning':
            //    return history.push(`/admin/planning/${User.compagny}`)
            case 'iot':
                return history.push('/admin/iot/properties')
            default:
                return null
        }
    }

    return (
        isOpen && (
            <div className="Menu p-2">
                <div className="d-flex justify-content-start my-2">
                    <div className="Menu_item-link Menu_item-link-green">
                        <img
                            className="Menu__icon mb-2"
                            src="/icons/chat-green.svg"
                            onClick={() => redirectTo('messenger')}
                            alt="messenger menu icon"
                        />
                        Messagerie
                    </div>
                    {(User?.isCompanyAdmin || User?.companySiren) && (
                        <div className="Menu_item-link Menu_item-link-blue">
                            <img
                                className="Menu__icon mb-2"
                                src="/icons/setting-blue.svg"
                                onClick={() => redirectTo('param')}
                                alt="files menu icon"
                            />
                            Paramètres
                        </div>
                    )}
                </div>

                {isFromAcodege && (
                    <div className="d-flex justify-content-start my-2">
                        <Link
                            onContextMenu={handleOpenContextMenu}
                            onClick={toggle}
                            to={`/admin/planning/${User.compagny}`}
                            className="Menu_item-link Menu_item-link-blue"
                        >
                            <img
                                className="Menu__icon mb-2 mt-1"
                                src="/icons/calendar-blue.svg"
                                alt="planning menu icon"
                            />
                            Planning
                        </Link>
                        <div className="Menu_item-link Menu_item-link-green">
                            {isFromAcodege && (
                                <>
                                    <img
                                        className="Menu__icon mb-2 mt-1"
                                        src="/icons/table-green.svg"
                                        onClick={() => redirectTo('list_person')}
                                        alt="files menu icon"
                                    />
                                    Tableau
                                </>
                            )}
                        </div>
                    </div>
                )}

                {isIotUser && (
                    <div className="d-flex justify-content-center my-2">
                        <div className="Menu_item-link Menu_item-link-blue">
                            <img
                                className="Menu__icon mb-2"
                                src={getLogoSrc('iot-logo')}
                                onClick={() => redirectTo('iot')}
                                alt="iot menu icon"
                            />
                            Monitoring
                        </div>
                    </div>
                )}
            </div>
        )
    )
}

export default Menu
