import { combineReducers } from 'redux'

import settingsReducer from './settings.reducer.js'
import themesReducer from './themes.reducers.js'
import authReducer from './auth.reducers.js'
import socketReducer from './socket.reducers.js'
import personReducer from './person.reducers.js'
import omessageReducer from './omessage.reducers.js'
import coreReducer from './core.reducers.js'
import identitoReducer from './identito.reducers.js'
import statReducer from './stat.reducers.js'
import eiReducer from './ei.reducers.js'
import planningReducer from './planning.reducers'
import notificationReducer from './notification.reducers'
import searchReducer from './search.reducer'
import structuresReducer from './structures.reducer'
import activitiesReducer from './activities.reducer'
import rolesReducer from './roles.reducer'
import permissionsReducer from './permissions.reducer'
import drawerReducer from './drawer_reducer'
import notificationsReducer from './notifications_reducer'
import iotReducer from './iot.reducer.js'

export default combineReducers({
    settings: settingsReducer,
    theme: themesReducer,
    auth: authReducer,
    socket: socketReducer,
    person: personReducer,
    omessage: omessageReducer,
    core: coreReducer,
    identito: identitoReducer,
    stat: statReducer,
    ei: eiReducer,
    planning: planningReducer,
    notification: notificationReducer,
    search: searchReducer,
    structures: structuresReducer,
    activities: activitiesReducer,
    roles: rolesReducer,
    permissions: permissionsReducer,
    drawer: drawerReducer,
    notifications: notificationsReducer,
    iot: iotReducer
})
