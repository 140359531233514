import { GET_ROLES, ADD_ROLE, UPDATE_ROLE, DELETE_ROLE, PRO_FOR_PLANNING } from '../actions/roles.actions'

const rolesReducer = (state = [], action) => {
    switch (action.type) {
        case PRO_FOR_PLANNING:
            return action.payload
        case GET_ROLES:
            return action.payload

        case ADD_ROLE:
            const newRole = action.payload
            return [...state, newRole]

        case UPDATE_ROLE:
            const updatedRole = action.payload
            return state.map(activity => (activity._id !== updatedRole._id ? activity : updatedRole))

        case DELETE_ROLE:
            const deletedRoleId = action.payload
            return state.filter(({ _id }) => _id !== deletedRoleId)

        default:
            return state
    }
}

export default rolesReducer
