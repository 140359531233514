import settings from './settings.middleware.js'
import themes from './themes.middleware.js'
import auth from './auth.middleware.js'
import socket from './socket.middleware.js'
import person from './person.middleware.js'
import omessage from './omessage.middleware.js'
import core from './core.middleware.js'
import identito from './identito.middleware.js'
import stat from './stat.middleware.js'
import ei from './ei.middleware.js'
import planning from './planning.middleware.js'
import notification from './notification.middleware.js'

export default [settings, themes, auth, socket, person, omessage, core, identito, stat, ei, planning, notification]
