import { USER_COMPAGNY, USER, UNAUTH_USER, UPDATEIMAGES, MAJPROFIL, USERPROFIL } from '../actions/actions'
import { REFUSED } from 'dns'

const auth = store => next => action => {
    let result = next(action)

    if (action.type === USER_COMPAGNY) {
        return { auth: true, user_compagny: action.payload }
    }
    if (action.type === MAJPROFIL) {
        return { auth: true, majprofil: action.payload }
    }

    if (action.type === USER) {
        return { auth: true, user: action.payload }
    }

    if (action.type === UNAUTH_USER) {
        return { auth: false, user: action.payload }
    }
    if (action.type === USERPROFIL) {
        return { auth: true, user: action.payload }
    }

    return result
}

export default auth
