import { LISTNOTIFICATION, CURRENTGRPNOTIFICATION, DETAILNOTIFICATION, SHOWDETAIL } from '../actions/actions'

const initialState = {
    listnotification: [],
    currentgrpnotification: false,
    detailnotification: false,
    showdetail: false
}

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case LISTNOTIFICATION:
            return {
                ...state,
                listnotification: action.payload
            }

        case SHOWDETAIL:
            return {
                ...state,
                showdetail: action.payload
            }

        case DETAILNOTIFICATION:
            return {
                ...state,
                detailnotification: action.payload
            }

        case CURRENTGRPNOTIFICATION:
            return {
                ...state,
                currentgrpnotification: action.payload
            }

        default:
            return state
    }
}

export default notificationReducer
