import axios from 'axios'

import { LIST_PERSON } from './person.actions'
import { getFromSessionStorage } from '../../utils/manageSessionStorage'

export const MYSTRUCTURE = 'MYSTRUCTURE'
export const USERINSTRUCTURE = 'USERINSTRUCTURE'
export const ADDUSERINSTRUCTURE = 'ADDUSERINSTRUCTURE'
export const ROLES = 'ROLES'
export const ROLE = 'ROLE'
export const PECHADS = 'PECHADS'
export const PROS = 'PROS'
export const GRPNOTIFICATIONS = 'GRPNOTIFICATIONS'
export const COMPANIES = 'COMPANIES'

const URL_PRO_SANTE_GOV = `https://public.opendatasoft.com/api/records/1.0/search/`
const config = require('../../config')

const ROOT_URL = config.url_gateway

const CancelToken = axios.CancelToken
let cancel

export function listgrpnotificationBOMy() {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .get(`${ROOT_URL}/core/notification_my?my=true`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: GRPNOTIFICATIONS, payload: response.data })
            })
            .catch(() => {})
    }
}

export function listgrpnotificationBO(id) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .get(`${ROOT_URL}/core/notification?id=${id}`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: GRPNOTIFICATIONS, payload: response.data })
            })
            .catch(() => {})
    }
}

export function setRole(role) {
    return function (dispatch) {
        dispatch({ type: ROLE, payload: role })
    }
}

export function searchProGov(name, city) {
    if (cancel !== undefined) {
        cancel()
    }

    return function (dispatch) {
        if (name === '') {
            return dispatch({ type: LIST_PERSON, payload: [] })
        }

        const token = getFromSessionStorage('sessionToken')
        axios
            .get(
                `${URL_PRO_SANTE_GOV}/?dataset=annuaire-des-professionnels-de-sante&q=${name}&facet=civilite&facet=exercice_particulier&facet=nature_exercice&facet=convention&facet=sesam_vitale&facet=types_actes&facet=codes_ccam&facet=nom_epci&facet=nom_dep&facet=nom_reg&facet=nom_com&facet=libelle_profession`,
                {
                    headers: { 'x-session': token },
                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c
                    })
                }
            )
            .then(response => {
                //Set Tie to expire
                dispatch({ type: PROS, payload: response.data })
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('pros get Request canceled')
                }
            })
    }
}

export function getallPecHad() {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .get(`${ROOT_URL}/core/pechad`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: PECHADS, payload: response.data })
            })
            .catch(() => {})
    }
}

export function mystructure() {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .get(`${ROOT_URL}/core/info`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: MYSTRUCTURE, payload: response.data })
            })
            .catch(() => {})
    }
}

export function userinstructures(data) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .post(
                `${ROOT_URL}/core/usersinstructure`,
                { id: data },
                {
                    headers: { 'x-session': token }
                }
            )
            .then(response => {
                //Set Tie to expire
                dispatch({ type: USERINSTRUCTURE, payload: response.data })
            })
            .catch(() => {})
    }
}

export function getAllCompanies() {
    const token = getFromSessionStorage('sessionToken')

    return function (dispatch) {
        axios
            .get(`${ROOT_URL}/core/companies`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: COMPANIES, payload: response.data })
            })
            .catch(() => {
                //no companies
            })
    }
}
