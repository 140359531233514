import sha1 from 'sha1'
import CryptoJS, { AES } from 'crypto-js'
export default {
    // RSA: {
    //     async GENERATE() {
    //         return await RSA.generateKeys(4096);
    //     },
    //     async ENCODE(message, publicKey) {
    //         return await RSA.encrypt(message, publicKey);
    //     },
    //     async DECODE(message, privateKey) {
    //         return await RSA.decrypt(message, privateKey);
    //     }
    // },
    AES: {
        // GCM: {
        //     async ENCRYPT(key, data, inBinary = false) {
        //         return await AesGcmCrypto.encrypt(data, inBinary, key)
        //     },
        //     async DECRYPT(key, data, iv, tag, isBinary = false) {
        //         return await AesGcmCrypto.decrypt(data, key, iv, tag, isBinary)
        //     },
        //     async GENERATE() {
        //     var t = Date.now();
        //     let keys = await crypto.subtle.generateKey(
        //         {
        //             name: "AES-GCM",
        //             length: 256, //can be  128, 192, or 256
        //         },
        //         false, //whether the key is extractable (i.e. can be used in exportKey)
        //         ["encrypt", "decrypt"] //can "encrypt", "decrypt", "wrapKey", or "unwrapKey"
        //     )
        //     console.log('Certificate AES-GCM-256 generated in [' + ((Date.now() - t) / 1000) + 's] in worker threads')
        //     return keys;
        // },
        CBC: {
            ENCODE(key, data) {
                //return CryptoES.AES.encrypt(key, data, { format: CryptoESJsonFormatter });
                return AES.encrypt(JSON.stringify(data), key).toString()
            },
            DECODE(key, data) {
                //return CryptoES.AES.decrypt(key, data).toString(CryptoES.enc.Utf8);
                let bytes = AES.decrypt(data, key)
                bytes = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
                return bytes
            }
        }
    },
    // PBKDF2: {
    //     GENERATE(passphrase) {
    //         const salt = CryptoES.lib.WordArray.random(128 / 8);
    //         // const key128Bits = CryptoES.PBKDF2("Secret Passphrase", salt, { keySize: 128/32 });
    //         // const key256Bits = CryptoES.PBKDF2("Secret Passphrase", salt, { keySize: 256/32 });
    //         // const key512Bits = CryptoES.PBKDF2("Secret Passphrase", salt, { keySize: 512/32 });
    //         const key512Bits1000Iterations = CryptoES.PBKDF2(passphrase, salt, {keySize: 512 / 32, iterations: 1000});
    //         return key512Bits1000Iterations;
    //     }
    // },
    SALT: {
        PASSWORD(string) {
            string = sha1(sha1(sha1(string)))
            return string
        }
    }
}

// const CryptoESJsonFormatter = {
//     stringify: function (cipherParams) { // create json object with ciphertext
//         const jsonObj = { ct: cipherParams.ciphertext.toString(CryptoES.enc.Base64) }; // optionally add iv and salt
//         if (cipherParams.iv) {
//             jsonObj.iv = cipherParams.iv.toString();
//         }
//         if (cipherParams.salt) {
//             jsonObj.s = cipherParams.salt.toString();
//         }
//         // stringify json object
//         return JSON.stringify(jsonObj);
//     },
//     parse: function (jsonStr) { // parse json string
//         const jsonObj = JSON.parse(jsonStr); // extract ciphertext from json object, and create cipher params object
//         const cipherParams = CryptoES.lib.CipherParams.create(
//             { ciphertext: CryptoES.enc.Base64.parse(jsonObj.ct) },
//         ); // optionally extract iv and salt
//         if (jsonObj.iv) {
//             cipherParams.iv = CryptoES.enc.Hex.parse(jsonObj.iv)
//         }
//         if (jsonObj.s) {
//             cipherParams.salt = CryptoES.enc.Hex.parse(jsonObj.s)
//         }
//         return cipherParams;
//     },
// };
