/**
 *
 * @param {string} name
 */
export const getLocalStorageItem = name => {
    return localStorage.getItem(name)
}

/**
 *
 * @param {string} name
 * @param {*} value
 */
export const setLocalStorageItem = (name, value) => {
    return localStorage.setItem(name, value)
}

/**
 *
 * @param name
 */
export const removeLocalStorageItem = name => {
    return localStorage.removeItem(name)
}
