import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NotificationItem from './Item'

import { getUserNotificationsAction } from '../../store/actions/actions'
import { selectNotificationsState } from '../../store/selectors/notifications_selector'

import './List.scss'

const NotificationsList = () => {
    const dispatch = useDispatch()
    const observer = useRef()

    const { payload: User } = useSelector(({ auth }) => auth)
    const notificationsState = useSelector(state => selectNotificationsState(state))
    const { list: notifications, hasMore } = notificationsState

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)

    const lastNotificationElementRef = useCallback(
        node => {
            if (loading) return
            if (observer.current) observer.current.disconnect()
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasMore) setPageNumber(prevState => prevState + 1)
            })
            if (node) observer.current.observe(node)
        },
        [hasMore, loading]
    )

    useEffect(() => {
        setLoading(true)
        setError(false)
        try {
            User?._id && dispatch(getUserNotificationsAction(User._id, pageNumber))
            setLoading(false)
        } catch {
            setLoading(false)
            setError(true)
        }
    }, [dispatch, pageNumber, User])

    return notifications?.length ? (
        <div className="NotificationsList">
            {notifications.map((notification, i) => {
                if (notifications.length === i + 1)
                    return (
                        <NotificationItem
                            ref={lastNotificationElementRef}
                            notification={notification}
                            key={notification._id}
                        />
                    )
                return <NotificationItem notification={notification} key={notification._id} />
            })}
            {loading && <div>Loading...</div>}
            {error && <div>Error</div>}
        </div>
    ) : (
        <div className="NotificationsList__empty-message">Vous n'avez pas de notifications récentes</div>
    )
}

export default NotificationsList
