import { TYPEEI, ADDEI, LISTEI, CURRENTEI, UPDATEI } from '../actions/actions'

const initialState = {
    typeei: [],
    addei: false,
    listei: [],
    currentei: {},
    updateei: false
}

const statReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPEEI:
            return {
                ...state,
                typeei: action.payload
            }

        case ADDEI:
            return {
                ...state,
                addei: action.payload
            }

        case LISTEI:
            return {
                ...state,
                listei: action.payload
            }

        case UPDATEI:
            return {
                ...state,
                updateei: action.payload
            }

        case CURRENTEI:
            return {
                ...state,
                currentei: action.payload
            }

        default:
            return state
    }
}

export default statReducer
