import { GET_ACTIVITIES, ADD_ACTIVITY, UPDATE_ACTIVITY, DELETE_ACTIVITY } from '../actions/activities.actions'

const activitiesReducer = (state = [], action) => {
    switch (action.type) {
        case GET_ACTIVITIES:
            return action.payload

        case ADD_ACTIVITY:
            const newActivity = action.payload
            return [...state, newActivity]

        case UPDATE_ACTIVITY:
            const updatedActivity = action.payload
            return state.map(activity => (activity._id !== updatedActivity._id ? activity : updatedActivity))

        case DELETE_ACTIVITY:
            const deletedActivityId = action.payload
            return state.filter(({ _id }) => _id !== deletedActivityId)

        default:
            return state
    }
}

export default activitiesReducer
