import { getRecentCommonGroups, updateProfessionalImage, updateProfessionalProfile } from '../../api/professionals'
import { getUserStructures } from '../../api/structures'

import { PERSON } from './person.actions'

export const GET_COMMON_GROUPS = 'GET_COMMON_GROUPS'
export const GET_PRO_STRUCTURES = 'GET_PRO_STRUCTURES'
export const RESET_COMMON_GROUPS = 'RESET_COMMON_GROUPS'

/**
 *
 * @param {string} file
 * @param {string} proId
 */

export const updateProfessionalImageAction = (file, proId) => async dispatch => {
    try {
        const response = await updateProfessionalImage(file, proId)
        if (response) {
            dispatch({ type: PERSON, payload: response.data })
        }
    } catch (error) {
        console.log(error)
        throw new Error('Une erreur est survenue')
    }
}

/**
 *
 * @param {object} professionalData
 */

export const updateProfessionalProfileAction = professionalData => async dispatch => {
    try {
        const response = await updateProfessionalProfile(professionalData)
        dispatch({ type: PERSON, payload: response.data })
    } catch (error) {
        console.log(error)
        throw new Error('Une erreur est survenue')
    }
}

/**
 *
 * @param {string} participantId
 */

export const getRecentCommonGroupsAction = participantId => async dispatch => {
    try {
        const response = await getRecentCommonGroups(participantId)
        dispatch({ type: GET_COMMON_GROUPS, payload: response.data })
    } catch (error) {
        console.log(error)
        //throw new Error('Une erreur est survenue')
    }
}

export const resetCommonGroupsAction = () => async dispatch => {
    return dispatch({ type: RESET_COMMON_GROUPS })
}

/**
 *
 * @param {[string]} structuresIds
 */

export const getProStructuresAction = (structuresIds) => async dispatch => {
    try {
        const { data: structures } = await getUserStructures(structuresIds)
        return dispatch({
            type: GET_PRO_STRUCTURES,
            payload: structures ?? []
        })
    } catch (err) {
        console.log(err)
    }
}
