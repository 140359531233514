import Crypter from './crypter'

export default function decodeLastMessageConversations(conversations) {
    for (let conversation of conversations) {
        try {
            if (conversation.lastMessage) {
                conversation.lastMessage = Crypter.AES.CBC.DECODE(conversation.masterkey, conversation.lastMessage)
            } else conversation.lastMessage = 'Pas encore de message'
        } catch (e) {}
    }

    return conversations
}
