import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Rollbar from 'rollbar'
// App Routes
import Routes from './Routes'

// Vendor dependencies
import './Vendor'
// Application Styles
import './styles/bootstrap.scss'
import './styles/app.scss'

const rollbarconfig = {
    accessToken: 'de0ca38bad5c40ebbba409bc759cdd9a',
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: 'production'
}

const rollbar = process.env.REACT_APP_ENV === 'production' ? new Rollbar(rollbarconfig) : ''

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rollbar: rollbar,
            error: null
        }
    }

    static getDerivedStateFromError(error) {
        return { isError: true }
    }

    componentDidCatch(error) {
        this.setState({ error })
    }

    render() {
        // specify base href from env varible 'PUBLIC_URL'
        // use only if application isn't served from the root
        // for development it is forced to root only
        /* global PUBLIC_URL */
        const basename = process.env.NODE_ENV === 'development' ? '/' : PUBLIC_URL || '/'

        return (
            <BrowserRouter basename={basename}>
                <Routes error={this.state.error} />
            </BrowserRouter>
        )
    }
}

export default App
