import axios from 'axios'

import config from '../config'
import { getFromSessionStorage } from '../utils/manageSessionStorage'

const ROOT_URL = config.url_gateway
const CancelToken = axios.CancelToken
let cancel

export const getAllPersonsStructures = async structures => {
    const token = getFromSessionStorage('sessionToken')
    if (cancel !== undefined) {
        cancel()
    }
    return axios.get(`${ROOT_URL}/person/person/array_person_structure/${structures}`, {
        headers: { 'x-session': token },
        cancelToken: new CancelToken(function executor(c) {
            cancel = c
        })
    })
}

export const addBeneficiary = beneficiaryData => {
    const token = getFromSessionStorage('sessionToken')

    return axios.post(`${ROOT_URL}/person/person`, beneficiaryData, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {object} beneficiaryData
 */

export const updateBeneficiary = beneficiaryData => {
    const token = getFromSessionStorage('sessionToken')

    return axios.put(`${ROOT_URL}/person/person`, beneficiaryData, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {object} beneficiaryData
 */

export const updateBeneficiaryPersonalContacts = beneficiaryData => {
    const token = getFromSessionStorage('sessionToken')

    return axios.put(`${ROOT_URL}/person/person/personal-contacts`, beneficiaryData, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {object} beneficiaryData
 */

export const updateBeneficiaryProContacts = beneficiaryData => {
    const token = getFromSessionStorage('sessionToken')

    return axios.put(`${ROOT_URL}/person/person/professional-contacts`, beneficiaryData, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {string} beneficiaryId
 */

export const getDiscussionGroups = beneficiaryId => {
    const token = getFromSessionStorage('sessionToken')
    return axios.get(`${ROOT_URL}/messaging/beneficiary-groups/${beneficiaryId}`, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {object} conversationData
 */

export const createGroupDiscussion = conversationData => {
    const token = getFromSessionStorage('sessionToken')
    return axios.put(`${ROOT_URL}/messaging/addconversationgrp`, conversationData, {
        headers: { 'x-session': token }
    })
}
