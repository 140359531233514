import {
    GRPNOTIFICATIONS,
    PROS,
    PECHADS,
    ROLE,
    ROLES,
    MYSTRUCTURE,
    USERINSTRUCTURE,
    ADDUSERINSTRUCTURE
} from '../actions/actions'

const core = store => next => action => {
    let result = next(action)

    if (action.type === GRPNOTIFICATIONS) {
        return { auth: true, grpnotifications: action.payload }
    }

    if (action.type === MYSTRUCTURE) {
        return { auth: true, mystructure: action.payload }
    }

    if (action.type === PROS) {
        return { auth: true, pros: action.payload }
    }

    if (action.type === PECHADS) {
        return { auth: true, pechads: action.payload }
    }

    if (action.type === ROLES) {
        return { auth: true, roles: action.payload }
    }
    if (action.type === ROLE) {
        return { auth: true, role: action.payload }
    }

    if (action.type === ADDUSERINSTRUCTURE) {
        return { auth: true, adduserinstructure: action.payload }
    }

    if (action.type === USERINSTRUCTURE) {
        return { auth: true, userinstructure: action.payload }
    }

    return result
}

export default core
