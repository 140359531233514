import { getUserNotifications } from '../../api/notifications'

export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS'
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const MARK_NOTIFICATIONS_SEEN = 'MARK_NOTIFICATIONS_SEEN'

const regex = new RegExp('supprimé', 'gi')

/**
 *
 * @param {string} userId
 * @param {number} pageNumber
 */

export const getUserNotificationsAction = (userId, pageNumber) => async dispatch => {
    try {
        const response = await getUserNotifications(userId, pageNumber)
        // check if there are notifications for canceled events and return the id of these events
        const canceledEvents = response.data.filter(d => d.title.match(regex)).map(({event: {id}}) => id)
        // add property "canceled" on all the notification concerning an already canceled event
        const notifications = response.data.map(notification => (canceledEvents.includes(notification.event?.id)) ? {...notification, canceled: true } : notification)
        return dispatch({
            type: GET_USER_NOTIFICATIONS,
            payload: notifications
        })
    } catch (err) {
        console.log(err)
    }
}

export const addNotificationAction = (notification) => dispatch => {
    dispatch({
        type: ADD_NOTIFICATION,
        payload: notification.title.match(regex) ? {...notification, canceled: true} : notification
    })
}

export const markNotificationsSeenAction = (notificationsIds, userId) => dispatch =>{
    dispatch({
        type: MARK_NOTIFICATIONS_SEEN,
        payload: { notificationsIds, userId }
    })
}
