import axios from 'axios'

import config from '../../config'
import { getFromSessionStorage } from '../../utils/manageSessionStorage'
import { searchUsers } from '../../api/messagingv2'

export const SEARCH_RESULT = 'SEARCH_RESULT'
export const SEARCH_RESULT_PROS = 'SEARCH_RESULT_PROS'
export const RESET_SEARCH_RESULT = 'RESET_SEARCH_RESULT'

const ROOT_URL = config.url_gateway

const CancelToken = axios.CancelToken
let cancel

/**
 *
 * @param {string} searchValue
 */

const searchBeneficiaries = searchValue => {
    const token = getFromSessionStorage('sessionToken')

    return axios
        .get(`${ROOT_URL}/person/person?name=${searchValue}`, { headers: { 'x-session': token } })
        .then(response => response)
        .catch(error => {
            console.warn('Error', error)
            return { data: [] }
        })
}

/**
 *
 * @param {string} searchValue
 */

const searchProfessionals = searchValue => {
    const token = getFromSessionStorage('sessionToken')
    return axios
        .get(`${ROOT_URL}/auth/finduser?name=${searchValue}`, {
            headers: { 'x-session': token },
            cancelToken: new CancelToken(function executor(c) {
                cancel = c
            })
        })
        .then(response => response)
        .catch(error => {
            console.warn('Error', error)
            return { data: [] }
        })
}

/**
 *
 * @param {string} searchValue
 */

export const searchPeopleAction = searchValue => async (dispatch, getState) => {
    const state = getState()
    const { _id: currentUserId } = state.auth.payload
    const {
        data: { data: professionals }
    } = await searchUsers(searchValue)
    try {
        const { data: patients } = await searchBeneficiaries(searchValue)

        const searchResultBeneficiaries = patients
            .filter(({ _id }) => _id !== currentUserId)
            .map(patient => ({ ...patient, pro: false }))

        const searchResultProfessionals = professionals
            .filter(({ _id }) => _id !== currentUserId)
            .map(professional => ({
                ...professional,
                pro: true
            }))

        const searchResultAll = [...searchResultBeneficiaries, ...searchResultProfessionals].sort((a, b) => {
            const nameA = a.prenom ? a.prenom.toLowerCase() : a.firstname.toLowerCase()
            const nameB = b.prenom ? b.prenom.toLowerCase() : b.firstname.toLowerCase()
            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
        })

        dispatch({
            type: SEARCH_RESULT,
            payload: {
                searchResultBeneficiaries,
                searchResultProfessionals,
                searchResultAll
            }
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {string} searchValue
 * @param {array} structures
 */

export const searchProfessionalsAction = (searchValue, structures) => async dispatch => {
    const {
        data: { data: professionals }
    } = await searchUsers(searchValue)
    const searchResult = professionals.map(professional => ({
        ...professional,
        pro: true
    }))
    dispatch({
        type: SEARCH_RESULT_PROS,
        payload: searchResult
    })
    return searchResult
}

export const resetSearchPeopleAction = () => dispatch => {
    dispatch({
        type: RESET_SEARCH_RESULT
    })
}
