import {
    SIZEINPUTCOMPOSE,
    POSITIONCOMPOSE,
    INCOMMINGCALL,
    SEARCHMESSAGEVALUE,
    SEARCHMESSAGE,
    ADDCOMMENT,
    MESSAGE,
    CONVERSATION,
    LISTCONV,
    MESSAGES,
    LISTPERSONSEARCH,
    CONVID,
    CONFCALL,
    CLOSECONFCALL,
    MUTECONV,
    DELETE_CONVERSATION,
    SET_MESSAGE_TO_EDIT,
    EDIT_MESSAGE,
    DELETE_MESSAGE,
    SET_CONV_TO_TOP,
    GET_COMMON_GROUPS,
    RESET_COMMON_GROUPS,
    FREE_MESSAGES,
    WS_NEW_MESSAGE,
    WS_NEW_CONVERSATION,
    DEFINE_NEW_LAST_MESSAGE,
    REMOVE_PARTICIPANTS,
    ADD_PARTICIPANTS,
    WS_EDIT_CONVERSATION,
    NEW_MESSAGE,
    COMPLETE_MESSAGE,
    LIST_CONTACTS_FOR_ONE_TO_ONE,
    INCREMENT_MESSAGES,
    ADD_PARTICIPANT
} from '../actions/actions'

const initialState = {
    listconv: [],
    messages: [],
    message: {},
    messageToEdit: null,
    listpersonsearch: [],
    chatgrpinfo: false,
    convid: '',
    conversation: {},
    confmodal: false,
    muteconv: false,
    addcomment: false,
    searchmessage: false,
    searchmessagevalue: '',
    incommingcall: false,
    positioncompose: 'absolute',
    sizeinputcompose: '300',
    convToTop: null,
    commonGroups: null,
    listContactsForOneToOne: []
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LISTCONV:
            return {
                ...state,
                listconv: action.payload
            }
        case WS_NEW_CONVERSATION:
            return {
                ...state,
                listconv: [action.payload, ...state.listconv]
            }
        case DEFINE_NEW_LAST_MESSAGE:
            let convsWithConvToEditLastMessage = state.listconv
            const lengthOfFilesOrPictures = action.payload[0]?.dataFiles
                ? action.payload[0].dataFiles?.length
                : action.payload[0]?.dataPictures
                ? action.payload[0].dataPictures?.length
                : 0
            let convToEditLastMessage = state.listconv.find(({ id }) => id === action.payload[0].conversationId)
            if (action.payload[0].dataText) convToEditLastMessage.lastMessage = action.payload[0].dataText
            else if (action.payload[0].dataPictures)
                convToEditLastMessage.lastMessage = `${
                    lengthOfFilesOrPictures > 1 ? `${lengthOfFilesOrPictures} photos` : '1 photo'
                }`
            else if (action.payload[0].dataFiles)
                convToEditLastMessage.lastMessage = `${
                    lengthOfFilesOrPictures > 1 ? `${lengthOfFilesOrPictures} documents` : '1 document'
                }`
            else if (action.payload[0].dataSystem)
                convToEditLastMessage.lastMessage = action.payload[0].dataSystem?.terminated
                    ? 'Appel terminé'
                    : 'Appel en cours'
            const indexConversationToEdit = state.listconv.findIndex(
                ({ id }) => id === action.payload[0].conversationId
            )
            convsWithConvToEditLastMessage[indexConversationToEdit] = {
                ...convsWithConvToEditLastMessage[indexConversationToEdit],
                ...convToEditLastMessage
            }
            return {
                ...state,
                conversation: convToEditLastMessage,
                listconv: convsWithConvToEditLastMessage
            }
        case SET_MESSAGE_TO_EDIT:
            const messageToEdit = action.payload
            return {
                ...state,
                messageToEdit
            }
        case EDIT_MESSAGE:
            let messages = state.messages
            let messageUpdate = messages.find(({ key }) => key === action.payload.messageKey)
            for (const object of action.payload.index) {
                messageUpdate = { ...messageUpdate, [object['key']]: object['value'] }
            }
            const messageIndex = messages.findIndex(({ id }) => id === messageUpdate.id)
            messages[messageIndex] = messageUpdate
            return {
                ...state,
                messages
            }
        case DELETE_MESSAGE:
            let newMessages = state.messages
            if (state.conversation.id === action.payload.conversationId) {
                newMessages.find(({ key }) => key === action.payload.messageKey).deleted = true
            }
            return {
                ...state,
                messages: newMessages
            }
        case DELETE_CONVERSATION:
            let newConversationIfCurrentIsDeleted = state.conversation
            const listConvWithoutDeletedConversation = state.listconv?.filter(({ id }) => id !== action.payload.id)
            newConversationIfCurrentIsDeleted.id === action.payload.id &&
                (newConversationIfCurrentIsDeleted = listConvWithoutDeletedConversation[0])
            return {
                ...state,
                listconv: listConvWithoutDeletedConversation,
                conversation: newConversationIfCurrentIsDeleted
            }
        case WS_EDIT_CONVERSATION:
            const { id: conversationId, index } = action.payload
            const convs = state.listconv
            let convUpdate = state.listconv?.find(({ id }) => id === conversationId)
            for (const object of index) {
                convUpdate = { ...convUpdate, [object['key']]: object['value'] }
            }
            const convIndex = convs.findIndex(({ id }) => id === conversationId)
            convs[convIndex] = convUpdate

            return {
                ...state,
                listconv: convs,
                conversation: state.conversation.id === conversationId ? convUpdate : state.conversation
            }
        case REMOVE_PARTICIPANTS:
            const convsWithRemovedParticipant = state.listconv
            const currentConvWithRemovedParticipant = state.conversation

            !convsWithRemovedParticipant
                .find(({ id }) => id === action.payload.id)
                .leaveParticipants.some(id => id === action.payload.participantId) &&
                convsWithRemovedParticipant
                    .find(({ id }) => id === action.payload.id)
                    .leaveParticipants.push(action.payload.participantId)

            !currentConvWithRemovedParticipant.leaveParticipants.some(id => id === action.payload.conversationId) &&
                currentConvWithRemovedParticipant.leaveParticipants.push(action.payload.participantId)

            return {
                ...state,
                listconv: convsWithRemovedParticipant,
                conversation: currentConvWithRemovedParticipant
            }
        case ADD_PARTICIPANTS:
            const convsWithNewParticipants = state.listconv
            const convToEdit = convsWithNewParticipants.find(({ id }) => id === action.payload.id)
            convToEdit.leaveParticipants = convToEdit.leaveParticipants?.filter(
                id => !action.payload.participants.some(participant => participant.id === id)
            )
            convToEdit.participants = action.payload.participants

            return {
                ...state,
                listconv: convsWithNewParticipants,
                conversation: state.conversation.id === action.payload.id ? convToEdit : state.conversation
            }
        case NEW_MESSAGE:
            return {
                ...state,
                messages: [...state.messages, action.payload],
                addcomment: false
            }
        case COMPLETE_MESSAGE:
            if (state.conversation.id === action.payload.conversationId) {
                let messageToComplete = state.messages?.find(({ key }) => key === action.payload.messageKey)
                for (const element of action.payload.index) {
                    messageToComplete = { ...messageToComplete, [element.key]: element.value }
                }
                return {
                    ...state,
                    messages: [
                        ...state.messages.filter(({ key }) => key !== action.payload.messageKey),
                        messageToComplete
                    ]
                }
            }
        case SIZEINPUTCOMPOSE:
            return {
                ...state,
                sizeinputcompose: action.payload
            }

        case LIST_CONTACTS_FOR_ONE_TO_ONE:
            return {
                ...state,
                listContactsForOneToOne: action.payload
            }
        case POSITIONCOMPOSE:
            return {
                ...state,
                positioncompose: action.payload
            }

        case INCOMMINGCALL:
            return {
                ...state,
                incommingcall: action.payload
            }

        case SEARCHMESSAGE:
            return {
                ...state,
                searchmessage: action.payload
            }

        case SEARCHMESSAGEVALUE:
            return {
                ...state,
                searchmessagevalue: action.payload
            }

        case ADDCOMMENT:
            return {
                ...state,
                addcomment: action.payload
            }

        case CONVID:
            return {
                ...state,
                convid: action.payload
            }

        case MESSAGE:
            const { message, conversation } = action.payload
            return {
                ...state,
                message
                //messages: { count: state.messages.count + 1, messages: [...state.messages.messages, message] },
                /*conversation: {
                    ...state.conversation,
                    lastmessage: conversation.lastmessage,
                    lastmessage_id: conversation.lastmessage_id
                }*/
            }

        case CONVERSATION:
            return {
                ...state,
                conversation: action.payload
            }

        case CONFCALL:
            return {
                ...state,
                convid: action.payload,
                confmodal: true
            }
        case CLOSECONFCALL:
            return {
                ...state,
                convid: action.payload,
                confmodal: false
            }

        case MESSAGES:
            return {
                ...state,
                messages: action.payload,
                hasMoreMessages: action.payload.length >= 20
            }

        case INCREMENT_MESSAGES:
            const messagesPrevstate = state.messages?.length ? state.messages : []
            return {
                ...state,
                messages: [...action.payload, ...messagesPrevstate],
                hasMoreMessages: action.payload.length >= 20
            }

        case WS_NEW_MESSAGE:
            return {
                ...state,
                messages: [...state.messages, ...action.payload]
            }
        case FREE_MESSAGES:
            return {
                ...state,
                messages: action.payload
            }
        //REDUCER for mute/unmute a single conversation
        case MUTECONV:
            return {
                ...state,
                muteconv: action.payload
            }
        case LISTPERSONSEARCH:
            return {
                ...state,
                listpersonsearch: action.payload
            }
        case SET_CONV_TO_TOP:
            return {
                ...state,
                convToTop: action.payload
            }
        case GET_COMMON_GROUPS:
            return {
                ...state,
                lastGroups: action.payload
            }
        case RESET_COMMON_GROUPS:
            return {
                ...state,
                lastGroups: null
            }
        default:
            return state
    }
}

export default authReducer
