import { EVENT, LASTRDV, NEXTRDV, PLANNING, ABSENCE_PRO, SEARCHEVENT } from '../actions/actions'

const planning = store => next => action => {
    let result = next(action)
    if (action.type === SEARCHEVENT) {
        return { auth: true, searchEvent: action.payload }
    }
    if (action.type === ABSENCE_PRO) {
        return { auth: true, absence_pro: action.payload }
    }
    if (action.type === LASTRDV) {
        return { auth: true, lastRdv: action.payload }
    }
    if (action.type === NEXTRDV) {
        return { auth: true, nextRdv: action.payload }
    }
    if (action.type === PLANNING) {
        return { auth: true, planning: action.payload }
    }

    if (action.type === EVENT) {
        return { auth: true, event: action.payload }
    }

    return result
}

export default planning
