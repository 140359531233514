import 'core-js/es6/string'
import 'core-js/es6/array'
import 'core-js/es6/map'
import 'core-js/es6/set'
import 'core-js/es6/object'
import 'core-js/es6/promise'
import 'core-js/es7/object'
import 'core-js/es7/array'
import 'raf/polyfill'
import './i18n'
import axios from 'axios'
import App from './App'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'

import { IntlProvider } from 'react-intl'
import { addLocaleData } from 'react-intl'
import fr from 'react-intl/locale-data/fr'
import { setSessionExpiredAction } from './store/actions/actions'
import configureStore from './store/store'

addLocaleData([...fr])
const store = configureStore()

//if (process.env.REACT_APP_ENV !== 'local' && process.env.REACT_APP_ENV !== 'dev') {
axios.interceptors.request.use(
    request => {
        if (request.headers['x-session'] && typeof request.headers['x-session'] !== 'string') {
            return store.dispatch(setSessionExpiredAction())
        }
        return request
    },
    error => Promise.reject(error)
)

axios.interceptors.response.use(undefined, error => {
    if (error.response?.status === 401 || error.response?.data?.message === 'Request failed with status code 401') {
        return store.dispatch(setSessionExpiredAction())
    }
    if (error.response?.status === 503) {
        return (window.location.href = '/error')
    } else {
        return error
    }
})

ReactDOM.render(
    <Provider store={store}>
        <IntlProvider locale="fr">
            <App />
        </IntlProvider>
    </Provider>,
    document.getElementById('app')
)
