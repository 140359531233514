import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const Bubble = ({ background, border, borderStrong, children, className, size, ...props }) => (
    <div
        style={{
            width: size,
            height: size,
            minWidth: size,
            minHeight: size,
            backgroundColor: background,
            border: `solid ${borderStrong ? '2px' : '1px'} ${border}`,
            userSelect: 'none'
        }}
        className={classnames('Bubble rounded-circle d-flex justify-content-center align-items-center', className)}
        {...props}
    >
        {children}
    </div>
)

Bubble.propTypes = {
    borderStrong: PropTypes.bool,
    background: PropTypes.string.isRequired,
    border: PropTypes.string.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    size: PropTypes.number
}

Bubble.defaultProps = {
    borderStrong: true,
    children: null,
    className: '',
    size: 35
}

export default Bubble
