import axios from 'axios'

import { getFromSessionStorage } from '../../utils/manageSessionStorage'

export const CONTROLIDENTITO = 'CONTROLIDENTITO'
const config = require('../../config')
const ROOT_URL = config.url_gateway

export function controlidentito() {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .get(`${ROOT_URL}/identito/control`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: CONTROLIDENTITO, payload: response.data })
            })
            .catch(() => {})
    }
}
