import axios from 'axios'

import config from '../config'
import { getFromSessionStorage } from '../utils/manageSessionStorage'
import { getCookie } from '../utils/manageCookies'

const ROOT_URL = config.url_gateway

/**
 *
 * @param {string} structureId
 */

export const getProPlanning = structureId => {
    const token = getFromSessionStorage('sessionToken')
        ? getFromSessionStorage('sessionToken')
        : getCookie('sessionToken')
    return axios.get(`${ROOT_URL}/core/auth-user/proForPlanning/${structureId}`, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {string} structureId
 */

export const getRoles = structureId => {
    const token = getFromSessionStorage('sessionToken')
    return axios.get(`${ROOT_URL}/core/role-new/${structureId}`, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {object} roleData
 */

export const addRole = roleData => {
    const token = getFromSessionStorage('sessionToken')
    return axios.post(`${ROOT_URL}/core/role-new`, roleData, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {object} roleData
 */

export const updateRole = roleData => {
    const token = getFromSessionStorage('sessionToken')
    return axios.put(`${ROOT_URL}/core/role-new`, roleData, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {string} structureId
 * @param {string} roleId
 */

export const deleteRole = (structureId, roleId) => {
    const token = getFromSessionStorage('sessionToken')
    return axios.delete(`${ROOT_URL}/core/role-new/${structureId}/${roleId}`, {
        headers: { 'x-session': token }
    })
}
