import axios from 'axios'

import config from '../config'
import { getFromSessionStorage } from '../utils/manageSessionStorage'

const ROOT_URL = config.url_gateway

/**
 *
 * @param {string} structureId
 */
export const getIotBuildings = structureId => {
    const token = getFromSessionStorage('sessionToken')
    return axios.get(`${ROOT_URL}/iot/buildings`, {
        headers: { 'x-session': token },
        params: { structureId }
    })
}

/**
 *
 *
 */

export const getPingFromMs = () => {
    const token = getFromSessionStorage('_token')
    return axios.get(`${ROOT_URL}/iot/ping`, {
        headers: { authorization: token }
    })
}

/**
 *
 * @param {string} structureId
 */
export const getIotProperties = () => {
    const token = getFromSessionStorage('sessionToken')
    return axios.get(`${ROOT_URL}/iot/properties`, {
        headers: { 'x-session': token }
    })
}


/**
 *
 * @param {string} propertyId
 */
export const getPropertyStates = propertyId => {
    const token = getFromSessionStorage('sessionToken')
    return axios.get(`${ROOT_URL}/iot/properties/lastStates`, {
        headers: { 'x-session': token },
        params: { propertyId }
    })
}

/**
 *
 * @param {string} propertyId
 */
export const getPropertyIncidents = propertyId => {
    const token = getFromSessionStorage('sessionToken')
    return axios.get(`${ROOT_URL}/iot/incidents`, {
        headers: { 'x-session': token },
        params: { propertyId }
    })
}

/**
 *
 * @param {Object} incidentData
 */
export const saveIotIncident = incidentData => {
    const token = getFromSessionStorage('sessionToken')
    return axios.post(`${ROOT_URL}/iot/incidents`, incidentData, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {string} incidentId
 * @param {Object} incidentUpdates
 * @returns
 */
export const updateIotIncident = (incidentId, incidentUpdates) => {
    const token = getFromSessionStorage('sessionToken')
    return axios.put(`${ROOT_URL}/iot/incidents/${incidentId}`, incidentUpdates, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {string} structureId
 * @returns
 */
export const getSelectableStructurePatients = structureId => {
    const token = getFromSessionStorage('sessionToken')
    return axios.get(`${ROOT_URL}/iot/patients/${structureId}/availabilities`, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {string} room _id
 * @param {string} patient _id
 * @returns updated room
 */
export const handleNewRoomPatient = (room, patient) => {
    const token = getFromSessionStorage('sessionToken')
    return axios.post(`${ROOT_URL}/iot/roomPatients/`, { room, patient }, { headers: { 'x-session': token } })
}

/**
 *
 * @param {string} roomPatient _id
 * @param {object} [update] { leftOn: Date }
 * @returns updated room
 */
export const handleRemoveRoomPatient = (roomPatient, update) => {
    const token = getFromSessionStorage('sessionToken')
    return axios.put(`${ROOT_URL}/iot/roomPatients/${roomPatient}`, update, {
        headers: { 'x-session': token }
    })
}
