import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import * as actions from '../../store/actions/actions'
import { connect } from 'react-redux'

class BasePage extends React.Component {
    componentWillMount() {
        // this.props.actions.checkUser();
    }

    render() {
        return <div>{this.props.children}</div>
    }
}

BasePage.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
}

const mapStateToProps = state => ({ settings: state.settings, auth: state.auth })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(BasePage)
