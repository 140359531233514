import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addNotificationAction, markNotificationsSeenAction, toggleDrawerAction } from '../../store/actions/actions'
import { selectNotificationsState } from '../../store/selectors/notifications_selector'

import './HeaderIcon.scss'

const NotificationsHeaderIcon = ({ width }) => {
    const dispatch = useDispatch()

    const { payload: User } = useSelector(({ auth }) => auth)
    const { socket } = useSelector(({ socket }) => socket)
    const { list: notifications } = useSelector(state => selectNotificationsState(state))

    //const notificationSound = new Audio('/sounds/new_message.mp3')

    const [newNotifications, setNewNotifications] = useState([])

    const handleNewNotification = useCallback(
        notification => {
            //notificationSound.play()
            dispatch(addNotificationAction(notification))
        },
        [dispatch /*, notificationSound*/]
    )

    const handleToggleDrawer = () => {
        dispatch(toggleDrawerAction())
    }

    useEffect(() => {
        if (socket && User?._id) {
            socket.on('new_notif', notification => handleNewNotification(notification))
            socket.on('confirm_notifications_seen', seenNotifications =>
                dispatch(markNotificationsSeenAction(seenNotifications, User._id))
            )
        }
    }, [dispatch, handleNewNotification, socket, User])

    useEffect(() => {
        if (notifications?.length && User?._id)
            setNewNotifications(notifications.filter(n => !n.seen.includes(User._id)))
    }, [notifications, User])

    return (
        <div className="NotificationsHeaderIcon" onClick={handleToggleDrawer}>
            <img src="/icons/bell-blue.svg" style={{ width }} />
            {newNotifications.filter(({ seen }) => !seen.includes(User._id)).length ? (
                <div className="NotificationsHeaderIcon__badge" />
            ) : null}
        </div>
    )
}

export default NotificationsHeaderIcon
