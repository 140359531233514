import axios from 'axios'

import {
    addBeneficiary,
    getDiscussionGroups,
    createGroupDiscussion,
    updateBeneficiary,
    updateBeneficiaryPersonalContacts,
    updateBeneficiaryProContacts
} from '../../api/beneficiaries'
import { getBeneficiaryNotifications } from '../../api/notifications'
import config from '../../config'
import { getFromSessionStorage } from '../../utils/manageSessionStorage'
import { createGroupConversation, getBeneficiaryGroups, getUserById } from '../../api/messagingv2'
import decodeLastMessageConversations from '../../utils/decodeLastMessageConversations'

export const CREATE_PERSON_DISCUSSION = 'CREATE_PERSON_DISCUSSION'
export const GET_PERSON_DISCUSSIONS = 'GET_PERSON_DISCUSSIONS'
export const GET_PERSON_NOTIFICATIONS = 'GET_BENEFICIARY_NOTIFICATIONS'
export const LIST_PERSON = 'LIST_PERSON'
export const PERSON = 'PERSON'
export const RESET_PERSON = 'RESET_PERSON'
export const TIMESLOTS = 'TIMESLOTS'

const ROOT_URL = config.url_gateway

const CancelToken = axios.CancelToken
let cancel

export function freeListPerson() {
    return function (dispatch) {
        dispatch({ type: LIST_PERSON, payload: [] })
    }
}

export const updateTimeslots = (id, timeslots) => async dispatch => {
    const token = getFromSessionStorage('sessionToken')

    axios
        .put(
            `${ROOT_URL}/core/auth-user/updateTimeSlots/${id}`,
            { timeSlots: timeslots },
            {
                headers: { 'x-session': token }
            }
        )
        .then(({ data }) => dispatch({ type: TIMESLOTS, payload: data }))
        .catch(error => console.error(error))
}

export function updateImgPerson(imageData) {
    const token = getFromSessionStorage('sessionToken')
    const { file, personId } = imageData

    return function (dispatch) {
        axios
            .post(
                `${ROOT_URL}/person/person/image`,
                { file, personId },
                {
                    headers: { 'x-session': token }
                }
            )
            .then(response => {
                console.log(response.data)
                dispatch({ type: PERSON, payload: response.data })
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function searchpersonget(value) {
    if (cancel !== undefined) {
        cancel()
    }
    const token = getFromSessionStorage('sessionToken')

    return function (dispatch) {
        if (value === '') {
            return dispatch({ type: LIST_PERSON, payload: [] })
        }

        axios
            .get(`${ROOT_URL}/person/person?name=${value}`, {
                headers: { 'x-session': token },
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c
                })
            })
            .then(response => {
                //Set Tie to expire
                dispatch({ type: LIST_PERSON, payload: response.data })
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('person post Request canceled')
                }
            })
    }
}

export function detailperson(id, pro = false) {
    const token = getFromSessionStorage('sessionToken')
    return async function (dispatch) {
        if (!pro) {
            return axios
                .get(`${ROOT_URL}/person/person?id=${id}`, {
                    headers: { 'x-session': token }
                })
                .then(response => {
                    dispatch({ type: PERSON, payload: response.data })
                })
                .catch(() => {})
        } else {
            const {
                data: { data }
            } = await getUserById(id)
            return dispatch({ type: PERSON, payload: data })
        }
        /*return axios
            .post(
                `${ROOT_URL}/auth/finduserbyid`,
                { arraysearch: [id] },
                {
                    headers: { 'x-session': token }
                }
            )
            .then(response => {
                const personData = response.data[0]
                const { compagny: company } = personData
                if (company)
                    axios
                        .get(`${ROOT_URL}/core/company/${company}`, {
                            headers: { 'x-session': token }
                        })
                        .then(response => {
                            dispatch({ type: PERSON, payload: { ...personData, companyName: response.data.nom } })
                        })
                        .catch(() => {})
                else dispatch({ type: PERSON, payload: { ...personData, companyName: null } })
            })
            .catch(() => {})*/
    }
}

export const resetCurrentPersonAction = () => dispatch => {
    dispatch({
        type: RESET_PERSON
    })
}

/**
 *
 * @param {object} beneficiaryData
 */

export const addBeneficiaryAction = beneficiaryData => async dispatch => {
    try {
        const response = await addBeneficiary(beneficiaryData)
        return dispatch({
            type: PERSON,
            payload: response.data
        })
    } catch (err) {
        console.log(err)
        throw new Error(err.message)
    }
}

/**
 *
 * @param {object} beneficiaryData
 */

export const updateBeneficiaryAction = beneficiaryData => async dispatch => {
    try {
        const response = await updateBeneficiary(beneficiaryData)
        return dispatch({
            type: PERSON,
            payload: response.data
        })
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }
}

/**
 *
 * @param {object} beneficiaryData
 */

export const updateBeneficiaryPersonalContactsAction = beneficiaryData => async dispatch => {
    try {
        const response = await updateBeneficiaryPersonalContacts(beneficiaryData)
        return dispatch({
            type: PERSON,
            payload: response.data
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {object} beneficiaryData
 */

export const updateBeneficiaryProContactsAction = beneficiaryData => async dispatch => {
    try {
        const response = await updateBeneficiaryProContacts(beneficiaryData)
        return dispatch({
            type: PERSON,
            payload: response.data
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {string} participantId
 */

export const getBeneficiaryDiscussionGroupsAction = beneficiaryId => async dispatch => {
    try {
        const {
            data: { data }
        } = await getBeneficiaryGroups(beneficiaryId)
        const decodedConv = decodeLastMessageConversations(data)
        dispatch({ type: GET_PERSON_DISCUSSIONS, payload: decodedConv })
    } catch (error) {
        console.log(error)
        throw new Error('Une erreur est survenue')
    }
}

export const createGroupDiscussionAction = conversationData => async dispatch => {
    try {
        const {
            data: { data }
        } = await createGroupConversation(conversationData)
        dispatch({
            type: CREATE_PERSON_DISCUSSION,
            payload: { ...data.conversation, lastMessage: 'Pas encore de message' }
        })
    } catch (error) {}
}

/**
 *
 * @param {string} beneficiaryId
 */

export const getBeneficiaryNotificationsAction = beneficiaryId => async dispatch => {
    try {
        const response = await getBeneficiaryNotifications(beneficiaryId)
        return dispatch({
            type: GET_PERSON_NOTIFICATIONS,
            payload: response.data
        })
    } catch (err) {
        console.log(err)
        throw new Error(err.message)
    }
}
