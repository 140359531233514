import axios from 'axios'
import config from '../config'
import { getFromSessionStorage } from '../utils/manageSessionStorage'
import { fetchData } from '../utils/fetchData'

const ROOT_URL = config.url_gateway

/**
 *
 * @param {string} id
 * @param {string} type
 */

export const getCountTypeEvent = (id, type) => {
    const token = getFromSessionStorage('sessionToken')
    return axios.get(`${ROOT_URL}/planning/get_count_type_event/${id}?type=${type}`, {
        headers: { 'x-session': token }
    })
}

export const getGlobalExport = (obj, type, structures, proId = '') =>
    fetchData('get', 'planning/global_export', {
        query: { ...obj, type, structures: JSON.stringify(structures), proId }
    })

export const checkPersonBusy = (obj, id) => fetchData('get', `planning/check_person_busy/${id}`, { query: obj })

export const editMissingPro = (id, obj) => fetchData('post', `planning/edit_missing_pro/${id}`, { body: obj })
