import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NotificationsList from '../Notification/List'

import { toggleDrawerAction } from '../../store/actions/drawer_actions'

import './Drawer.scss'
import { selectNotificationsState } from '../../store/selectors/notifications_selector'

function Drawer() {
    const dispatch = useDispatch()

    const { isOpen } = useSelector(({ drawer }) => drawer)
    const { socket } = useSelector(({ socket }) => socket)
    const { payload: User } = useSelector(({ auth }) => auth)
    const { list: notifications } = useSelector(state => selectNotificationsState(state))

    const [newNotifications, setNewNotifications] = useState([])

    const toggleDrawer = () => {
        if (newNotifications.length && isOpen)
            socket.emit(
                'notifications_seen',
                newNotifications.map(({ _id }) => _id)
            )
        dispatch(toggleDrawerAction())
    }

    useEffect(() => {
        if (notifications?.length && User?._id)
            setNewNotifications(notifications.filter(n => !n.seen.includes(User._id)))
    }, [notifications, User])

    return (
        <div className={classnames('Drawer px-3', isOpen && 'open')}>
            <h3 className="text-center mb-4">Centre de notifications</h3>
            <NotificationsList />
            <img
                className="Drawer__close-icon"
                src="/icons/arrow-right-blue.svg"
                alt="close icon"
                onClick={toggleDrawer}
            />
        </div>
    )
}

export default Drawer
