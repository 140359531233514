import React, { Suspense, lazy } from 'react'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader'
import Base from './components/Layout/Base'
import BaseAdmin from './components/Layout/Base'
import BasePage from './components/Layout/BasePage'
import BaseVideo from './components/Layout/BaseVideo'
import ErrorPage from './components/Pages/Error'
import { getFromSessionStorage } from './utils/manageSessionStorage'

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />

//PAGES UNAUTH
const Login = lazy(() => import('./components/Pages/Login'))
const Register = lazy(() => import('./components/Pages/Register'))
const ValidAccount = lazy(() => import('./components/Pages/ValidAccount'))
const ValidAccountToken = lazy(() => import('./components/Pages/ValidAccountToken'))
const validRecover = lazy(() => import('./components/Pages/validRecover'))
const resetPassword = lazy(() => import('./components/Pages/resetPassword'))
const CodeAuth = lazy(() => import('./components/Pages/CodeAuth'))
const Cgu = lazy(() => import('./components/Pages/CGU'))

const Recover = lazy(() => import('./components/Pages/Recover'))
const Lock = lazy(() => import('./components/Pages/Lock'))
const NotFound = lazy(() => import('./components/Pages/NotFound'))
const Maintenance = lazy(() => import('./components/Pages/Maintenance'))

//PAGES OPERATIONAL
const Search = lazy(() => import('./components/Search/View'))
const ProfessionalView = lazy(() => import('./components/Pages/Professional'))
const BeneficiaryView = lazy(() => import('./components/Pages/Beneficiary'))

const Trans = lazy(() => import('./components/Person/Trans'))
const Geoloc = lazy(() => import('./components/Person/Geoloc'))
const Ei = lazy(() => import('./components/Person/Ei'))
const EiDetail = lazy(() => import('./components/Person/EiDetail'))
const Planning = lazy(() => import('./components/Planning/Planning'))

const Profile = lazy(() => import('./components/Pages/Profile'))
const Messenger = lazy(() => import('./components/Messaging/Messenger'))
const Notification = lazy(() => import('./components/Notifications/Home'))

const VideoComponent = lazy(() => import('./components/Omessage/VideoComponent'))

const Conference = lazy(() => import('./components/Conference/Conference'))

//PAGES ADMIN
const ExportTabPersons = lazy(() => import('./components/Admin/ExportTabPersons'))
const Settings = lazy(() => import('./components/Admin/Settings/Settings'))

//PAGES CUSTOM ROLE

const StatArs = lazy(() => import('./components/Stat/StatArs'))

//PAGES TOGI
const Iot = lazy(() => import('./components/Pages/Iot'))
const IotProperty = lazy(() => import('./components/Pages/Iot/Property.jsx'))
const IotStats = lazy(() => import('./components/Pages/Iot/Stats.jsx'))
const IotLogbook = lazy(() => import('./components/Pages/Iot/Logbook.jsx'))

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    '/error',
    '/login',
    '/cgu',
    '/register',
    '/recover',
    '/lock',
    '/notfound',
    '/maintenance',
    '/validaccount',
    '/validRecover',
    '/validaccounttoken',
    '/resetPassword',
    '/codeauth'
]

const listofAdmin = ['/admin/settings', '/admin/list_person', '/admin/planning/:id', '/admin/iot/properties']

const Routes = ({ error, location, history }) => {
    const currentKey = location.pathname.split('/')[1] || '/'
    const timeout = { enter: 500, exit: 500 }

    const animationName = 'rag-fadeIn'
    const currentPage = location.pathname.endsWith('/') ? location.pathname.slice(0, -1) : location.pathname

    if (error && location.pathname.indexOf('error') === -1)
        return (
            <Redirect
                to={{
                    pathname: '/error',
                    state: {
                        statusCode: 500,
                        location: location,
                        error: error
                    }
                }}
            />
        )
    else if (listofPages.indexOf(currentPage) > -1) {
        return (
            // Page Layout component wrapper

            <BasePage location={location}>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route exact path="/login" component={waitFor(Login)} />
                        <Route exact path="/error" component={waitFor(ErrorPage)} />
                        <Route exact path="/cgu" component={waitFor(Cgu)} />
                        <Route exact path="/codeauth" component={waitFor(CodeAuth)} />
                        <Route exact path="/register" component={waitFor(Register)} />
                        <Route exact path="/validaccount" component={waitFor(ValidAccount)} />
                        <Route exact path="/validaccounttoken" component={waitFor(ValidAccountToken)} />
                        <Route exact path="/validRecover" component={waitFor(validRecover)} />
                        <Route exact path="/resetPassword" component={waitFor(resetPassword)} />
                        <Route exact path="/recover" component={waitFor(Recover)} />
                        <Route exact path="/lock" component={waitFor(Lock)} />
                        <Route exact path="/notfound" component={waitFor(NotFound)} />
                        <Route exact path="/maintenance" component={waitFor(Maintenance)} />
                    </Switch>
                </Suspense>
            </BasePage>
        )
    } else if (
        listofAdmin.indexOf(currentPage) > -1 ||
        location.pathname.split('/')[2] === 'statview' ||
        location.pathname.split('/')[2] === 'planning' ||
        location.pathname.split('/')[3] === 'properties'
    ) {
        return (
            <Base location={history}>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        {/*User management*/}
                        <Route exact path="/admin/settings" component={waitFor(Settings)} />
                        <Route exact path="/admin/list_person" component={waitFor(ExportTabPersons)} />
                        <Route exact path="/admin/planning/:id" component={waitFor(Planning)} />
                        <Route exact path="/admin/iot/properties/:id/logbook" component={waitFor(IotLogbook)} />
                        <Route exact path="/admin/iot/properties/:id/stats" component={waitFor(IotStats)} />
                        <Route exact path="/admin/iot/properties/:id" component={waitFor(IotProperty)} />
                        <Route exact path="/admin/iot/properties" component={waitFor(Iot)} />
                        <Route exact path="/">
                            <Redirect to="/search" />
                        </Route>

                        <Redirect
                            to={{
                                pathname: `/error`,
                                state: {
                                    statusCode: 404
                                }
                            }}
                        />
                    </Switch>
                </Suspense>
            </Base>
        )
    } else if (currentPage === '/messenger' || currentPage === '/conference' || currentPage === '/notification') {
        return (
            <BaseVideo location={location} history={history}>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route exact path="/messenger" component={waitFor(Messenger)} />
                        <Route exact path="/conference" menu="false" component={waitFor(Conference)} />
                        <Route exact path="/notification" component={waitFor(Notification)} />
                    </Switch>
                </Suspense>
            </BaseVideo>
        )
    } else
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            <Base location={history}>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        {/*User management*/}
                        <Route exact path="/profile" component={waitFor(Profile)} />

                        {/*Search page*/}
                        <Route exact path="/search" component={waitFor(Search)} />
                        <Route
                            exact
                            path={['/beneficiary/:id/planning', '/beneficiary/:id/planning', '/beneficiary/:id']}
                            component={waitFor(BeneficiaryView)}
                        />
                        <Route
                            exact
                            path={[
                                '/professional/:id/planning/:date',
                                '/professional/:id/planning',
                                '/professional/:id'
                            ]}
                            component={waitFor(ProfessionalView)}
                        />
                        <Route exact path="/geoloc/:id" component={waitFor(Geoloc)} />
                        <Route exact path="/trans/:id" component={waitFor(Trans)} />
                        <Route exact path="/ei/:id" component={waitFor(Ei)} />
                        <Route exact path="/event/:id" component={waitFor(EiDetail)} />
                        <Route exact path="/activity-report" component={waitFor(StatArs)} />
                        <Route exact path="/video" component={waitFor(VideoComponent)} />

                        <Route exact path="/">
                            <Redirect to="/search" />
                        </Route>
                        <Redirect
                            to={{
                                pathname: `/error`,
                                state: {
                                    statusCode: 404
                                }
                            }}
                        />
                    </Switch>
                </Suspense>
            </Base>
        )
}

export default withRouter(Routes)
