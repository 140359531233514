import { GET_COMPANY_STRUCTURES, GET_USER_STRUCTURES, ADD_STRUCTURE, UPDATE_STRUCTURE, DELETE_STRUCTURE } from '../actions/structures.actions'

const initialState = {
    companyStructures: [],
    userStructures: [],
}

const structuresReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANY_STRUCTURES:
            return {
                ...state,
                companyStructures: action.payload
            }

        case GET_USER_STRUCTURES:
            return {
                ...state,
                userStructures: action.payload
            }

        case ADD_STRUCTURE:
            const newStructure = action.payload
            return {
                ...state,
                companyStructures: [...state.companyStructures, newStructure]
            }

        case UPDATE_STRUCTURE:
            const updatedStructure = action.payload
            return {
                ...state,
                companyStructures: state.companyStructures.map(structure => (structure._id !== updatedStructure._id ? structure : updatedStructure))
            }

        case DELETE_STRUCTURE:
            const deletedStructureId = action.payload
            return {
                ...state,
                companyStructures: state.companyStructures.filter(({ _id }) => _id !== deletedStructureId)
            }

        default:
            return state
    }
}

export default structuresReducer
