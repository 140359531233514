import { LIST_PERSON, PERSON } from '../actions/actions'

const person = store => next => action => {
    let result = next(action)

    if (action.type === LIST_PERSON) {
        return { auth: true, listperson: action.payload }
    }

    if (action.type === PERSON) {
        return { auth: true, person: action.payload }
    }

    return result
}

export default person
