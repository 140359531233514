import { STATPERSON } from '../actions/actions'

const stat = store => next => action => {
    let result = next(action)

    if (action.type === STATPERSON) {
        return { auth: true, statperson: action.payload }
    }

    return result
}

export default stat
