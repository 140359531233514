import axios from 'axios'

import config from '../config'
import { getFromSessionStorage } from '../utils/manageSessionStorage'

const ROOT_URL = config.url_gateway

/**
 *
 * @param {string} file
 * @param {string} proId
 */

export const updateProfessionalImage = (file, proId) => {
    const token = getFromSessionStorage('sessionToken')
    return axios.put(
        `${ROOT_URL}/auth/updateproimageurl`,
        { file: file, proId },
        {
            headers: { 'x-session': token }
        }
    )
}

/**
 *
 * @param {object} professionalData
 */

export const updateProfessionalProfile = professionalData => {
    const token = getFromSessionStorage('sessionToken')
    return axios.put(`${ROOT_URL}/auth/pro`, professionalData, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {string} participantId
 */

export const getRecentCommonGroups = participantId => {
    const token = getFromSessionStorage('sessionToken')
    return axios.get(`${ROOT_URL}/messaging/recent-groups/${participantId}`, {
        headers: { 'x-session': token }
    })
}
