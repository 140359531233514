import { STATPERSON, STATAGGIR } from '../actions/actions'

const initialState = {
    statperson: []
}

const statReducer = (state = initialState, action) => {
    switch (action.type) {
        case STATPERSON:
            return {
                ...state,
                statperson: action.payload
            }
        case STATAGGIR:
            return {
                ...state,
                stataggir: action.payload
            }

        default:
            return state
    }
}

export default statReducer
