import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FormattedRelative } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import IconBubble from '../Search/IconBubble'

import { setConvToTop, toggleDrawerAction } from '../../store/actions/actions'

import './Item.scss'
import { selectNotificationsState } from '../../store/selectors/notifications_selector'

const NotificationItem = React.forwardRef(({ className, notification, isSeen }, ref) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { payload: user } = useSelector(({ auth }) => auth)
    const { socket } = useSelector(({ socket }) => socket)
    const { list: notifications } = useSelector(state => selectNotificationsState(state))

    const [newNotifications, setNewNotifications] = useState([])

    const { title, content, imageURL, seen, type, createdAt, event, conversation, canceled } = notification

    const handleClick = () => {
        if (type === 'planning' && !canceled) {
            if (newNotifications.length)
                socket.emit(
                    'notifications_seen',
                    newNotifications.map(({ _id }) => _id)
                )
            dispatch(toggleDrawerAction())
            history.push(`/professional/${user._id}/planning/${event.date}`, {
                state: {
                    id: event.id
                }
            })
        } else if (type === 'conversation' && conversation) {
            if (newNotifications.length)
                socket.emit(
                    'notifications_seen',
                    newNotifications.map(({ _id }) => _id)
                )
            dispatch(toggleDrawerAction())
            dispatch(setConvToTop(conversation))
            history.push(`/messenger`)
        } else return null
    }

    useEffect(() => {
        if (notifications?.length && user?._id)
            setNewNotifications(notifications.filter(n => !n.seen.includes(user._id)))
    }, [notifications, user])

    return (
        <div
            ref={ref}
            className={classnames(
                'NotificationItem d-flex flex-column-reverse flex-md-row justify-content-between align-items-md-center px-2 py-1',
                className
            )}
            onClick={handleClick}
        >
            <div className="d-flex w-100 overflow-hidden">
                <div className={classnames('NotificationItem__photo-container mx-2')}>
                    {imageURL ? (
                        <img src={imageURL} alt="sender photo" className={'NotificationItem__photo mx-1'} />
                    ) : (
                        <IconBubble
                            src={`/icons/${type === 'planning' ? 'calendar' : 'chat'}-white.svg`}
                            alt={`${type === 'planning' ? 'Planning' : 'Messenger'} icon`}
                            size={40}
                        />
                    )}
                </div>
                <div className="NotificationItem__information d-flex flex-column justify-content-center mx-2">
                    <div className="NotificationItem__information-container">
                        <div className="NotificationItem__information-title" title={title}>
                            {title}
                        </div>
                        <div className="NotificationItem__information-content" title={content}>
                            {content}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={classnames(
                    'NotificationItem__date-container mx-auto m-md-0 overflow-hidden text-truncate d-flex justify-content-center',
                    seen.includes(user?._id) || isSeen ? 'seen' : ''
                )}
            >
                <FormattedRelative value={createdAt} numeric="auto" updateInterval={60000} />
            </div>
        </div>
    )
})

NotificationItem.propTypes = {
    className: PropTypes.string,
    notification: PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        seen: PropTypes.arrayOf(PropTypes.string),
        imageURL: PropTypes.string,
        type: PropTypes.string
    }),
    isSeen: PropTypes.bool
}

NotificationItem.defaultProps = {
    className: '',
    isSeen: false
}

export default NotificationItem
