import Crypter from './crypter'

export default function decodeMessagesFromBaseConversations(conversations) {
    for (const conversation of conversations) {
        if (conversation.messages && conversation.messages.length) {
            for (const message of conversation.messages) {
                if (message.encoded) {
                    try {
                        if (message.dataText) {
                            message.dataText = Crypter.AES.CBC.DECODE(conversation.masterkey, message.dataText)
                        }
                        if (message.dataPictures) {
                            message.dataPictures = JSON.parse(
                                Crypter.AES.CBC.DECODE(conversation.masterkey, message.dataPictures)
                            )
                        }
                        if (message.dataFiles) {
                            const decrypted = Crypter.AES.CBC.DECODE(conversation.masterkey, message.dataFiles)
                            message.dataFiles =
                                typeof message.dataFiles === 'string' ? decrypted : JSON.parse(decrypted)
                        }
                        message.encoded = false
                    } catch (e) {}
                }
                if (message.replyAt) {
                    if (message.replyAt.encoded) {
                        try {
                            if (message.replyAt.dataText) {
                                message.replyAt.dataText = Crypter.AES.CBC.DECODE(
                                    conversation.masterkey,
                                    message.replyAt.dataText
                                )
                            }
                            if (message.replyAt.dataPictures) {
                                message.replyAt.dataPictures = JSON.parse(
                                    Crypter.AES.CBC.DECODE(conversation.masterkey, message.replyAt.dataPictures)
                                )
                            }
                            if (message.replyAt.dataFiles) {
                                const decrypted = Crypter.AES.CBC.DECODE(conversation.masterkey, message.dataFiles)
                                message.replyAt.dataFiles =
                                    typeof decrypted === 'string' ? decrypted : JSON.parse(decrypted)
                            }
                            message.replyAt.encoded = false
                        } catch (e) {}
                    }
                }
            }
        }
    }

    return conversations
}
