import { getRoles, addRole, updateRole, deleteRole, getProPlanning } from '../../api/roles'
import { getUserStructures } from '../../api/structures'

export const GET_ROLES = 'GET_ROLES'
export const ADD_ROLE = 'ADD_ROLE'
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE'
export const PRO_FOR_PLANNING = 'PRO_FOR_PLANNING'

/**
 *
 * @param {object} pro
 * @param {[object]} allPro
 */

export const setAbsenceForPro = (pro, allPro) => async dispatch => {
    try {
        dispatch({ type: PRO_FOR_PLANNING, payload: [pro, ...allPro.filter(item => item._id !== pro._id)] })
    } catch (error) {}
}

/**
 *
 * @param {string} structureId
 */

export const getProPlanningAction = structureId => async dispatch => {
    try {
        const { data } = await getProPlanning(structureId)
        return dispatch({ type: PRO_FOR_PLANNING, payload: data })
    } catch (e) {
        console.log(e)
        throw new Error(e)
    }
}

/**
 *
 * @param {string} structureId
 */

export const getRolesAction = structureId => async dispatch => {
    try {
        const { data: roles } = await getRoles(structureId)
        return dispatch({
            type: GET_ROLES,
            payload: roles
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {object} roleData
 */

export const addRoleAction = roleData => async dispatch => {
    try {
        const { data: role } = await addRole(roleData)
        return dispatch({
            type: ADD_ROLE,
            payload: role
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {object} roleData
 */

export const updateRoleAction = roleData => async dispatch => {
    try {
        const { data: role } = await updateRole(roleData)
        return dispatch({
            type: UPDATE_ROLE,
            payload: role
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {string} structureId
 * @param {string} roleId
 */

export const deleteRoleAction = (structureId, roleId) => async dispatch => {
    try {
        await deleteRole(structureId, roleId)
        return dispatch({
            type: DELETE_ROLE,
            payload: roleId
        })
    } catch (err) {
        console.log(err)
    }
}
