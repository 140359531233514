import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { ListGroupItem, Alert } from 'reactstrap'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../store/actions/actions'
import HeaderRun from './Header.run'
import Menu from '../Menu/Menu'
import NotificationsHeaderIcon from '../Notification/HeaderIcon'

import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { withNamespaces } from 'react-i18next'
import InitialsBubble from '../Bubble/InitialsBubble'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let timersound = null

var hidden, visibilityChange
if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
}

class Header extends Component {
    audio = new Audio('/sounds/iphonesms.mp3')
    tonalite = new Audio('/sounds/tonalite.mp3')
    //new_message = new Audio('/sounds/new_message.mp3')
    title = document.title
    docBody = document.getElementById('site-body')

    nbMsg = 0

    // docBody = document.getElementById('site-body');
    // docBody.onload = this.newUpdate();

    count = 0

    constructor(props, context) {
        super(props, context)

        this.state = {
            messages: [],
            socket: false,
            new_message: 0,
            logo: '/img/logo_mono.png',
            logo_small: '/img/logo_mono.png',
            videoConference: false,
            roomName: null,
            modalreceivecall: false,
            receivecall_imageURL: null,
            receivecall_name: null,
            user: null,
            currentconvid: null,
            receiveconf: false,
            grp: false,
            alertNewMessage: false,
            alertNewMessageName: null,
            alertNewMessageConvId: null,
            play: null,
            isMenuOpen: false
        }

        this.onMessage = this.onMessage.bind(this)
        this.goChat = this.goChat.bind(this)
        this.end_call = this.end_call.bind(this)
        this.readmessage = this.readmessage.bind(this)
        this.muteconv = this.muteconv.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)

        this.incomming_call = this.incomming_call.bind(this)
        this.togglePlay = this.togglePlay.bind(this)
    }

    onFocus = () => {
        // console.log('focus');
    }

    togglePlay() {
        if (timersound) {
            clearInterval(timersound)
            this.audio.pause()
            timersound = null
        } else {
            //     this.audio.play()
            let _this = this
            /*       timersound = setInterval(function () {
                console.log('play')
                _this.audio.play()
            }, 2000)*/
        }
        //this.audio.loop = true;
    }

    handleVisibilityChange() {
        document.title = "Hosmoz - Solutions numérique pour la coordination de l'aide et des soins à domicile"
    }

    componentDidMount() {
        HeaderRun()
        var now = Date.now()
        document.addEventListener(visibilityChange, this.handleVisibilityChange, false)
    }

    toggleUserblock = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('showUserBlock')
    }

    toggleOffsidebar = e => {
        e.preventDefault()
        console.log('Wake OffSideBar')
        if (this.props.settings?.offnotificationOpen) {
            this.props.actions.toggleSetting('offnotificationOpen')
        }
        this.props.actions.toggleSetting('offsidebarOpen')
        // this.props.actions.toggleSetting('offnotificationOpen');
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled')
    }

    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents')
        evt.initUIEvent('resize', true, false, window, 0)
        window.dispatchEvent(evt)
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }
    incomming_call(message) {
        //this.audio.play();
        console.log('incoming call', message)
        document.title = 'Hosmoz - Appel entrant'
        this.togglePlay()
        this.receivecall(message)
        this.setState({
            currentconvid: message.room,
            receiveconf: true
        })

        console.log('header', message)
    }

    callTwilioConference() {
        this.setState({
            modalreceivecall: false,
            videoConference: true
        })
    }

    receivecall(message) {
        this.setState({ roomName: message.room })
        this.setState({
            roomName: message.room,
            receivecall_imageURL: message['imageURL'],
            receivecall_name: message['name']
        })

        this.setState({
            modalreceivecall: true
        })
    }

    end_call(message) {
        this.setState({
            currentconvid: null,
            modalreceivecall: false
        })

        console.log('end call received')

        console.log(window.location.pathname)

        if (window.location.pathname.includes('conference')) {
            this.props.history.push('/messenger')
        }

        //   this.props.history.push('/omessage');

        console.log('modal closed')
    }

    onNewNotification(message) {
        this.props.actions.setOneNotification(message)
    }

    onListNotification(message) {
        console.log(message)
        this.props.actions.setListNotification(message)
    }

    onMessage(message) {
        //this.new_message.play()

        document.title = `Hosmoz - Nv. message !`

        if (window.location.pathname !== '/omessage') {
            let arraymessage = this.state.messages
            arraymessage.push(message)
            this.setState({ messages: arraymessage })
        }
        if (!this.state.alertNewMessage) {
            //console.log(message);
            this.setState({
                alertNewMessageName: message.sender_name,
                alertNewMessage: true,
                alertNewMessageConvId: message.conversation_id
            })

            setTimeout(() => {
                this.setState({
                    alertNewMessage: false
                })
            }, 100)
        }
    }

    readmessage(message) {
        document.title = "Hosmoz - Solutions numérique pour la coordination de l'aide et des soins à domicile"
        if (this.state.alertNewMessage === true)
            this.setState({
                alertNewMessageName: null,
                alertNewMessageConvId: null,
                alertNewMessage: false
            })
    }

    muteconv(message) {
        console.log('headermessage', message)
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    closeWindow = () => {
        this.setState({
            videoConference: false
        })
        this.props.actions.closeConference()
    }

    openP2pPage = () => {
        this.togglePlay()
        this.props.history.push(`/conference?p2pid=${this.state.roomName}`)

        //window.location.href = `/conference?p2pid=${this.state.currentconvid}`;
    }
    closeWindowP2p = () => {
        this.togglePlay()
        this.setState({
            modalreceivecall: false
        })
        //this.props.actions.closep2p();
        //console.log('currentconvid', this.state.roomName);
        this.props.actions.endcall({
            conversation_id: this.state.roomName,
            message: { name: this.state.user.name, imageURL: this.state.user.imageURL, room: this.state.roomName }
        })
    }

    notifCreationConf = () => {
        this.props.actions.addomessage({
            conversation_id: this.state.currentconvid,
            text: "Une salle de conférence vient d'être créé vous pouvez désormais la rejoindre",
            typemessage: 'text',
            sender_imageUrl: this.state.user.imageURL,
            sender_id: this.state.user._id,
            sender_name: this.state.user.name
        })

        setTimeout(() => this.props.actions.listmessages(this.state.currentconvid), 2000)
    }

    lock = () => {
        this.props.actions.lock()
        window.location.href = '/lock'
    }

    goChat = e => {
        e.preventDefault()
        this.setState({ messages: [] })
    }

    closeWindowPopup() {
        this.setState({
            videoConference: false
        })

        this.props.actions.endcall({
            conversation_id: this.state.currentconvid,
            message: {
                name: `${this.state.user.surname} ${this.state.user.name}`,
                imageURL: this.state.user.imageURL,
                room: this.state.currentconvid
            }
        })
        this.props.actions.closeConference()
    }

    closeWindowPopupGrp() {
        this.setState({
            videoConference: false
        })
        this.props.actions.closeConference()
    }
    disconnect = () => {
        this.props.actions.logout()
        window.location.href = '/login'
    }

    hangout() {
        this.togglePlay()
        this.setState({ modalreceivecall: false })
        this.props.actions.endcall({
            conversation_id: this.state.currentconvid,
            message: {
                name: this.state.user.name + ' ' + this.state.user.surname,
                imageURL: this.state.user.imageURL,
                room: this.state.currentconvid
            }
        })
    }

    hangup() {
        //console.log("Decrocher / Rejoindre la room ", this.state.roomName);

        // this.joinRoomReceive(this.state.roomName);
        this.togglePlay()
        this.tonalite.pause()
        this.callTwilioConference()
    }

    goAcc() {
        this.props.allprops.location.push('/search')
    }

    redirectToProfile() {
        this.props.allprops.location.push('/profile')
    }

    toggleMenu() {
        this.setState({
            isMenuOpen: !this.state.isMenuOpen
        })
    }

    render() {
        let newmessages
        let showLocalTrack = this.state.localMediaAvailable ? (
            <div style={{ backgroundColor: '#000000' }}>
                <div className="media-container" ref="localMedia" />{' '}
            </div>
        ) : (
            ''
        )

        let joinOrLeaveRoomButtonReceive = this.state.hasJoinedRoom ? (
            <div className="container" style={{ backgroundColor: '#000' }}>
                <div className="row" style={{ backgroundColor: '#000' }}>
                    <div className="col-sm-12 text-center">
                        <br />
                        <button className="btn btn-danger rounded-pill" onClick={this.leaveRoom}>
                            {' '}
                            <i className="fa fa-phone-slash" />
                        </button>
                    </div>
                </div>
            </div>
        ) : (
            <div className="container" style={{ backgroundColor: '#000' }}>
                <div className="row" style={{ backgroundColor: '#000' }}>
                    <div className="col-sm-6 text-center">
                        <button className="btn rounded-pill btn-danger" onClick={this.hangout.bind(this)}>
                            <i className="fa fa-phone-slash" />{' '}
                        </button>
                    </div>
                    <div className="col-sm-6 text-center">
                        <button className="btn rounded-pill btn-success" onClick={this.hangup.bind(this)}>
                            <i className="fa fa-phone" />{' '}
                        </button>
                    </div>
                </div>
            </div>
        )

        newmessages = this.state.messages.map((message, index) => (
            <ListGroupItem key={index} onClick={this.goChat}>
                <Link to="/omessage" title={'Omessage'} className="btn btn-block btn-secondary">
                    <div className="media">
                        <div className="align-self-start mr-2">
                            <img
                                src={message.sender_imageUrl}
                                className="align-self-center mr-2 rounded-circle img-thumbnail thumb48"
                                alt="img"
                            />
                        </div>
                        <div className="media-body">
                            <p className="m-0">{message.sender_name} dit :</p>
                            <p className="m-0 text-muted text-sm">{message.message}</p>
                        </div>
                    </div>
                </Link>
            </ListGroupItem>
        ))
        return (
            <header className="topnavbar-wrapper">
                <Modal isOpen={this.state.modalreceivecall}>
                    <ModalHeader>Appel</ModalHeader>
                    <ModalBody>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%'
                            }}
                        >
                            <div style={{ width: '100%', marginBottom: 40 }}>
                                <div className="text-center">
                                    {/* <img className="align-self-center mr-2 rounded-circle img-thumbnail thumb128" src={imageURL}  alt="img"/> */}
                                    <div className="ball-beat">
                                        <div style={{ marginTop: 5, maxWidth: 10, maxHeight: 10 }} />
                                        <div style={{ maxWidth: 10, maxHeight: 10 }} />
                                        <div style={{ maxWidth: 10, maxHeight: 10 }} />
                                    </div>

                                    <p style={{ color: '#fff', fontSize: 18 }} className="hosmoz__label__blue">
                                        {this.state.receivecall_name}
                                        <br />
                                        Appel entrant...
                                    </p>
                                </div>
                            </div>
                            <div className="text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-6 text-center">
                                            <button
                                                className="btn rounded-pill btn-danger"
                                                onClick={e => this.closeWindowP2p()}
                                            >
                                                <img style={{ width: 19 }} src="/img/phone-slash-solid.svg" />
                                            </button>
                                        </div>
                                        <div className="col-sm-6 text-center">
                                            <button
                                                className="btn rounded-pill btn-success"
                                                onClick={e => this.openP2pPage()}
                                            >
                                                <img style={{ width: 19 }} src="/img/phone-alt-solid.svg" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                {/*
                   {this.state.videoConference === true && (
                            <NewWindow title="Conférence" onBlock={() => alert('Impossible d\'ouvrir la fenetre, veuillez desactiver vos anti-pubs')} onUnload={() => !this.state.grp ? this.closeWindowPopup() : this.closeWindowPopupGrp() }>
                               <div style={{backgroundColor:"#000000",width:'100%', height: '100%', margin:'0 auto', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                   <VideoChat
                                        closeWindow={this.closeWindow}
                                        receiveconf={this.state.receiveconf}
                                        props={this.props} 
                                        conversationId={this.state.currentconvid}
                                        imageUrl={this.state.user.imageURL}
                                        name={`${this.state.user.surname} ${this.state.user.name}`}
                                        grp={this.state.grp}
                                        p2p={false}
                                        copyStyles={true}
                                        currentUrl={window.location.origin}
                                        receiveCallName={this.state.receivecall_name}
                                        notifCreationConf={this.notifCreationConf}
                                   />
                               </div>
                           </NewWindow>
                    )} */}
                {/* START Top Navbar */}
                <nav className="navbar topnavbar degradebar w-100 d-flex">
                    {/* START navbar header */}
                    <div className="navbar-header">
                        <span className="navbar-brand" style={{ cusror: 'pointer' }} onClick={this.goAcc.bind(this)}>
                            <div className="brand-logo">
                                <img
                                    src={this.state.logo}
                                    style={{ width: 50, marginTop: -6, cursor: 'pointer' }}
                                    alt="Hosmoz Logo"
                                    className="img-responsive"
                                />
                            </div>
                            <div className="brand-logo-collapsed">
                                <img
                                    src={this.state.logo_small}
                                    style={{ width: 30, marginTop: 1, cursor: 'pointer' }}
                                    alt="Hosmoz Logo"
                                    className="img-responsive"
                                />
                            </div>
                        </span>
                    </div>

                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item d-none d-md-block">
                            {this.state.alertNewMessage && (
                                <Alert
                                    color="primary"
                                    style={{
                                        position: 'absolute',
                                        top: '110%',
                                        right: '10px',
                                        zIndex: 3,
                                        backgroundColor: '#424E72'
                                    }}
                                >
                                    Vous avez un nouveau message de{' '}
                                    <a
                                        target="_blank"
                                        href={`/omessage?idConversation=${this.state.alertNewMessageConvId}`}
                                        style={{ color: '#9DC7B7' }}
                                    >
                                        {this.state.alertNewMessageName}
                                    </a>
                                </Alert>
                            )}
                        </li>
                    </ul>

                    {/* END Left navbar */}
                    {/* START Right Navbar */}

                    <ul className="navbar-nav d-flex flex-row align-items-center">
                        {/*<li className={'nav-item mx-2'}>
                            <i className={'fas fa-download fa-lg'} style={{ color: '#2d3e5f' }} />
                        </li>*/}
                        <li className="nav-item mx-2">
                            <NotificationsHeaderIcon width={25} />
                        </li>
                        <li className="nav-item mx-2" onClick={this.toggleMenu}>
                            <img src="/icons/menu-blue.svg" style={{ width: 25 }} />
                        </li>
                        <li className="nav-item mx-2" onClick={this.redirectToProfile.bind(this)}>
                            {this.props.auth.payload?.images?.['150'] ? (
                                <img
                                    className="image-fit-cover rounded-circle "
                                    style={{ cursor: 'pointer', border: '2px solid #F8AD80' }}
                                    src={this.props.auth.payload?.images?.['150']}
                                    alt="Profile"
                                    width="35"
                                    height="35"
                                />
                            ) : (
                                <InitialsBubble
                                    bubbleSize={30}
                                    firstName={this.props.auth.payload?.firstname ?? ''}
                                    fontSize={14}
                                    lastName={this.props.auth.payload?.lastname ?? ''}
                                    pro={true}
                                />
                            )}
                        </li>
                    </ul>
                </nav>
                <Menu isOpen={this.state.isMenuOpen} toggle={this.toggleMenu} />
            </header>
        )
    }
}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    socket: PropTypes.object,
    omessage: PropTypes.object
}

const mapStateToProps = state => ({
    settings: state.settings,
    socket: state.socket,
    auth: state.auth,
    omessage: state.omessage
})
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translations')(withRouter(Header)))
