import axios from 'axios'

import config from '../config'
import { getFromSessionStorage } from '../utils/manageSessionStorage'

const ROOT_URL = config.url_gateway

export const getPermissions = () => {
    const token = getFromSessionStorage('sessionToken')
    return axios.get(`${ROOT_URL}/core/permissions/`, {
        headers: { 'x-session': token }
    })
}
