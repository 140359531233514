import { getPermissions } from '../../api/permissions'

export const GET_PERMISSIONS = 'GET_PERMISSIONS'

export const getPermissionsAction = () => async dispatch => {
    try {
        const { data: permissions } = await getPermissions()
        return dispatch({
            type: GET_PERMISSIONS,
            payload: permissions
        })
    } catch (err) {
        console.log(err)
    }
}
