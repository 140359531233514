export const LISTNOTIFICATION = 'LISTNOTIFICATION'
export const CURRENTGRPNOTIFICATION = 'CURRENTGRPNOTIFICATION'
export const DETAILNOTIFICATION = 'DETAILNOTIFICATION'
export const SHOWDETAIL = 'SHOWDETAIL'

export function setListNotification(val) {
    return function (dispatch) {
        dispatch({ type: LISTNOTIFICATION, payload: val })
    }
}

export function setShowDetailNotif(val) {
    return function (dispatch) {
        dispatch({ type: SHOWDETAIL, payload: val })
    }
}

export function setCurrentGrpNotification(val) {
    return function (dispatch) {
        dispatch({ type: CURRENTGRPNOTIFICATION, payload: val })
    }
}

export function setDetailNotification(notif) {
    return function (dispatch) {
        dispatch({ type: DETAILNOTIFICATION, payload: notif })
    }
}
