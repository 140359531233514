import axios from 'axios'

import config from '../config'
import { getFromSessionStorage } from '../utils/manageSessionStorage'

const ROOT_URL = config.url_gateway

/**
 *
 * @param {string} id
 */

export const getActivitiesByUserId = id => {
    const token = getFromSessionStorage('sessionToken')

    return axios.get(`${ROOT_URL}/core/activities/getActivities/${id}`, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {string} structureId
 */

export const getActivities = structureId => {
    const token = getFromSessionStorage('sessionToken')

    return axios.get(`${ROOT_URL}/core/activities/${structureId}`, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {object} activityData
 */

export const addActivity = activityData => {
    const token = getFromSessionStorage('sessionToken')

    return axios.post(`${ROOT_URL}/core/activities`, activityData, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {object} activityData
 */

export const updateActivity = activityData => {
    const token = getFromSessionStorage('sessionToken')

    return axios.put(`${ROOT_URL}/core/activities`, activityData, {
        headers: { 'x-session': token }
    })
}

/**
 *
 * @param {string} structureId
 * @param {string} activityId
 */

export const deleteActivity = (structureId, activityId) => {
    const token = getFromSessionStorage('sessionToken')

    return axios.delete(`${ROOT_URL}/core/activities/${structureId}/${activityId}`, {
        headers: { 'x-session': token }
    })
}
