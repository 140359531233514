import { createStore, applyMiddleware } from 'redux'
import reducers from './reducers/reducers'
import middlewares from './middlewares/middlewares'
import reduxThunk from 'redux-thunk'
import logger from 'redux-logger'
import { updateTheme } from './middlewares/themes.middleware.js'

import { persistedState, saveState } from './persisted.store.js'

export default function configureStore() {
    const middlewareList = [...middlewares, reduxThunk]

    if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'local') {
        middlewareList.push(logger)
    }

    const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore)

    const store = createStoreWithMiddleware(
        reducers,
        persistedState, // second argument overrides the initial state
        applyMiddleware(...middlewareList)
    )

    // add a listener that will be invoked on any state change
    store.subscribe(() => {
        saveState(store.getState())
    })

    // Update the initial theme
    updateTheme(store.getState())

    return store
}
