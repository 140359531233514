import axios from 'axios'
import io from 'socket.io-client'

import config from '../../config'
import { getFromSessionStorage } from '../../utils/manageSessionStorage'
import { uploadFile } from '../../api/messaging'

const ROOT_URL = config.url_gateway

export const MESSAGES = 'MESSAGES'
export const MESSAGE = 'MESSAGE'
export const MUTECONV = 'MUTECONV'
export const LISTCONV = 'LISTCONV'
export const LISTPERSONSEARCH = 'LISTPERSONSEARCH'
export const CHATGRPINFO = 'CHATGRPINFO'
export const CONVID = 'CONVID'
export const CONFCALL = 'CONFCALL'
export const CLOSECONFCALL = 'CLOSECONFCALL'
export const DELETECONV = 'DELETECONV'
export const CONVERSATION = 'CONVERSATION'
export const ADDCOMMENT = 'ADDCOMMENT'
export const SEARCHMESSAGE = 'SEARCHMESSAGE'
export const SEARCHMESSAGEVALUE = 'SEARCHMESSAGEVALUE'
export const INCOMMINGCALL = 'INCOMMINGCALL'
export const POSITIONCOMPOSE = 'POSITIONCOMPOSE'
export const SIZEINPUTCOMPOSE = 'SIZEINPUTCOMPOSE'
export const SET_MESSAGE_TO_EDIT = 'SET_MESSAGE_TO_EDIT'
export const EDIT_MESSAGE = 'EDIT_MESSAGE'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION'
export const SET_CONV_TO_TOP = 'SET_CONV_TO_TOP'
export const FREE_MESSAGES = 'FREE_MESSAGES'

const CancelToken = axios.CancelToken
let cancel

export const freeSearchProEvent = () => async dispatch => dispatch({ type: LISTPERSONSEARCH, payload: [] })

export const freeMessages = () => async dispatch => dispatch({ type: FREE_MESSAGES, payload: [] })

export const freeListConversations = () => async dispatch => dispatch({ type: LISTCONV, payload: [] })

export const freeConversation = () => async dispatch => dispatch({ type: CONVERSATION, payload: {} })

export function setSizeInputCompose(val) {
    return function (dispatch) {
        dispatch({ type: SIZEINPUTCOMPOSE, payload: val })
    }
}

export function postionRenderMessage(val) {
    return function (dispatch) {
        dispatch({ type: POSITIONCOMPOSE, payload: val })
    }
}

export function setIncommingCall(val) {
    return function (dispatch) {
        dispatch({ type: INCOMMINGCALL, payload: val })
    }
}

export const uploadFileAction = (conversationId, file, fileName, fileType, replyAt) => async dispatch => {
    try {
        const { data } = await uploadFile(conversationId, file, fileName, fileType, replyAt)
        return dispatch({ type: MESSAGE, payload: data })
    } catch (e) {
        console.log(e)
        //throw new Error(e)
    }
}

export function outGrpBO(id, idUser) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')
        axios
            .delete(`${ROOT_URL}/messaging/member/${id}/${idUser}`, {
                headers: { 'x-session': token }
            })
            .catch(() => {})
    }
}

export function searchMesValue(value) {
    return function (dispatch) {
        dispatch({ type: SEARCHMESSAGEVALUE, payload: value })
    }
}

export function searchMes(state) {
    return function (dispatch) {
        dispatch({ type: SEARCHMESSAGE, payload: state })
    }
}

export function addcommentEdit(data) {
    return function (dispatch) {
        dispatch({ type: ADDCOMMENT, payload: data })
    }
}
export function listconversation(value) {
    return function (dispatch, getState) {
        const {
            omessage: { convToTop }
        } = getState()
        const token = getFromSessionStorage('sessionToken')
        axios
            .get(`${ROOT_URL}/messaging/conversation?search=${encodeURIComponent(value)}`, {
                headers: { 'x-session': token }
            })
            .then(response => {
                if (convToTop) {
                    const convToMove = response.data.find(({ conversation_id }) => conversation_id === convToTop)
                    const restConvs = response.data.filter(({ conversation_id }) => conversation_id !== convToTop)
                    return dispatch({
                        type: LISTCONV,
                        payload: [convToMove, ...restConvs]
                    })
                }
                return dispatch({ type: LISTCONV, payload: response.data })
            })
            .catch(() => {})
    }
}

export const deleteMemberAction = (conversationId, memberId) => async dispatch => {
    const token = getFromSessionStorage('sessionToken')

    try {
        const response = await axios.delete(`${ROOT_URL}/messaging/member/${conversationId}/${memberId}`, {
            headers: { 'x-session': token }
        })
        dispatch({ type: CHATGRPINFO, payload: response.data })
    } catch (error) {
        throw new Error('Une erreur est survenue')
    }
}

export const setMessageToEditAction = data => dispatch => {
    dispatch({
        type: SET_MESSAGE_TO_EDIT,
        payload: data
    })
}

export const editMessageAction = (messageId, modifiedMessage) => async dispatch => {
    const token = getFromSessionStorage('sessionToken')

    try {
        const response = await axios.put(
            `${ROOT_URL}/messaging/message/${messageId}`,
            { modifiedMessage },
            {
                headers: { 'x-session': token }
            }
        )
        dispatch(setMessageToEditAction(null))
        dispatch({
            type: EDIT_MESSAGE,
            payload: response.data
        })
    } catch (error) {
        console.log('error.response.message', error.response.message)
    }
}

export const deleteMessageAction = messageId => async dispatch => {
    const token = getFromSessionStorage('sessionToken')

    try {
        const response = await axios.delete(`${ROOT_URL}/messaging/message/${messageId}`, {
            headers: { 'x-session': token }
        })
        dispatch({
            type: DELETE_MESSAGE,
            payload: response.data
        })
    } catch (error) {
        console.log('error.response.message', error.response.message)
    }
}

export const refreshMessagesAction = (action, message) => dispatch => {
    switch (action) {
        case 'update':
            return dispatch({
                type: EDIT_MESSAGE,
                payload: message
            })
        case 'delete':
            return dispatch({
                type: DELETE_MESSAGE,
                payload: message
            })
        default:
            return null
    }
}

export const addmember = data => async dispatch => {
    const token = getFromSessionStorage('sessionToken')

    try {
        const response = await axios.put(`${ROOT_URL}/messaging/member`, data, {
            headers: { 'x-session': token }
        })
        dispatch({ type: CHATGRPINFO, payload: response.data })
    } catch (error) {
        console.log('error.response.message', error.response.message)
    }
}

export function listmessages(convid, skip = 0) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')

        axios
            .post(
                `${ROOT_URL}/messaging/messagesbydate?skip=${skip}`,
                {
                    conversation_id: convid
                },
                {
                    headers: { 'x-session': token }
                }
            )
            .then(response => {
                //Set Tie to expire
                dispatch({ type: MESSAGES, payload: response.data })
            })
            .catch(() => {})
    }
}
//Function for mute/unmute one conversation
export function muteconv(obj) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')

        axios
            .post(`${ROOT_URL}/messaging/muteconv`, obj, {
                headers: { 'x-session': token }
            })
            .then(response => {
                dispatch({ type: MUTECONV, payload: response.data })
            })
            .catch(() => {})
    }
}

export function addomessage(obj) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')

        axios
            .post(`${ROOT_URL}/messaging/addmessage`, obj, {
                headers: { 'x-session': token }
            })
            .then(response => {
                dispatch({ type: MESSAGE, payload: response.data })
            })
            .catch(() => {})
    }
}

export function write_progress(obj) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')

        axios
            .post(`${ROOT_URL}/messaging/writeprogress`, obj, {
                headers: { 'x-session': token }
            })
            .then(response => {})
            .catch(() => {})
    }
}

export function readmessage(obj) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')

        axios
            .post(`${ROOT_URL}/messaging/readmessage`, obj, {
                headers: { 'x-session': token }
            })
            .then(response => {})
            .catch(() => {})
    }
}

export function resetListSearch() {
    return function (dispatch) {
        dispatch({ type: LISTPERSONSEARCH, payload: [] })
    }
}

export function searchperson(name) {
    if (cancel !== undefined) {
        cancel()
    }

    return function (dispatch) {
        if (name === '') {
            return dispatch({ type: LISTPERSONSEARCH, payload: [] })
        }
        const token = getFromSessionStorage('sessionToken')

        axios
            .get(`${ROOT_URL}/auth/finduser?name=${encodeURIComponent(name)}`, {
                headers: { 'x-session': token },
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c
                })
            })
            .then(response => {
                dispatch({ type: LISTPERSONSEARCH, payload: response.data })
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('post Request canceled')
                }
            })
    }
}

export function editGrp(obj, id) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')

        axios
            .post(`${ROOT_URL}/messaging/conversation?id=${id}`, obj, {
                headers: { 'x-session': token }
            })
            .then(response => {
                dispatch({ type: CONVERSATION, payload: response.data })
            })
            .catch(() => {})
    }
}

export function addgrp(obj) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')

        axios
            .put(`${ROOT_URL}/messaging/addconversationgrp`, obj, {
                headers: { 'x-session': token }
            })
            .then(response => {
                dispatch({ payload: response.data, type: CONVERSATION })
            })
            .catch(() => {})
    }
}

export const setConvToTop = convId => dispatch => {
    dispatch({
        type: SET_CONV_TO_TOP,
        payload: convId
    })
}

export const addsimpleconv = obj => async dispatch => {
    try {
        const token = getFromSessionStorage('sessionToken')

        const response = await axios.put(`${ROOT_URL}/messaging/conversation`, obj, {
            headers: { 'x-session': token }
        })
        dispatch(listconversation())
        return response.data.conversation_id
    } catch (error) {
        console.log(`error`, error)
    }
}

export function sendCallConf(obj) {
    return function (dispatch) {
        dispatch({ type: CONFCALL, payload: obj })
    }
}

export function closeConference() {
    return function (dispatch) {
        dispatch({ type: CLOSECONFCALL, payload: { conversation_id: null } })
    }
}

export function sendcall(obj) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')

        axios
            .post(`${ROOT_URL}/messaging/sendcall`, obj, {
                headers: { 'x-session': token }
            })
            .then(response => {})
            .catch(() => {})
    }
}

export function endcall(obj) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')

        axios
            .post(`${ROOT_URL}/messaging/endcall`, obj, {
                headers: { 'x-session': token }
            })
            .then(response => {})
            .catch(() => {})
    }
}

export function chatgroupeinfo(obj) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')

        axios
            .post(`${ROOT_URL}/messaging/groupechatinfo`, obj, {
                headers: { 'x-session': token }
            })
            .then(response => {
                dispatch({ type: CHATGRPINFO, payload: response.data })
            })
            .catch(() => {})
    }
}

export function sendInviteBO(obj) {
    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')

        axios
            .put(`${ROOT_URL}/notification/invite`, obj, {
                headers: { 'x-session': token }
            })
            .then(response => {})
            .catch(() => {})
    }
}

export function sendGuestInvite(firstname, lastname, email, location, convid) {
    let obj = {
        firstname,
        lastname,
        email,
        location,
        convid
    }

    return function (dispatch) {
        const token = getFromSessionStorage('sessionToken')

        axios
            .put(`${ROOT_URL}/guest/invite`, obj, {
                headers: { 'x-session': token },
                params: { convid: obj.convid, firstname: obj.firstname, lastname: obj.lastname }
            })
            .then(response => {
                dispatch({ payload: response.data })

                //              // TODO : Here the node js will return the link with extra parameter such as access=XXXX,
                // So you must try in Omessage.jsx to visit a link E.g: http://localhost:3000/conference?convid=5e8c6d3bf0ecc11e24b722e8&access=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwiZmlyc3RuYW1lIjoiSm9obiIsImxhc3RuYW1lIjoiRG8iLCJndWVzdCI6dHJ1ZSwiaWF0IjoxNTE2MjM5MDIyfQ.kPNdF6eYU-B0kv10a3ETLgHtRmTA2h_T_T3CVF7lbG0
                // And allow him to be in the conference + get him a twilio token + set firtname & lastname regarding the parameters
                // The access token is a JWT token so you can read it and check the value in it
            })
            .catch(err => {
                console.log('err', err)
            })
    }
}

export function setConvId(convid) {
    return function (dispatch) {
        dispatch({ type: CONVID, payload: convid })
    }
}

export function setConvCurrent(conv) {
    return function (dispatch) {
        dispatch({ type: CONVERSATION, payload: conv })
    }
}
