import { CONTROLIDENTITO } from '../actions/actions'

const omessage = store => next => action => {
    let result = next(action)

    if (action.type === CONTROLIDENTITO) {
        return { auth: true, controlidentito: action.payload }
    }

    return result
}

export default omessage
