import { GET_PERMISSIONS } from '../actions/permissions.actions'

const permissionsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PERMISSIONS:
            return action.payload
        default:
            return state
    }
}

export default permissionsReducer
