import { getActivities, addActivity, updateActivity, deleteActivity, getActivitiesByUserId } from '../../api/activities'

export const GET_ACTIVITIES = 'GET_ACTIVITIES'
export const ADD_ACTIVITY = 'ADD_ACTIVITY'
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY'
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY'

export const freeActivities = () => async dispatch => {
    return dispatch({
        type: GET_ACTIVITIES,
        payload: []
    })
}

/**
 *
 * @param {string} id
 */

export const getActivitiesByUserIdAction = id => async dispatch => {
    try {
        const { data } = await getActivitiesByUserId(id)
        return dispatch({ type: GET_ACTIVITIES, payload: data })
    } catch (e) {
        console.log(e)
        throw new Error(e)
    }
}

/**
 *
 * @param {string} structureId
 */

export const getActivitiesAction = structureId => async dispatch => {
    try {
        const { data: activities } = await getActivities(structureId)
        return dispatch({
            type: GET_ACTIVITIES,
            payload: activities
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {object} activityData
 */

export const addActivityAction = activityData => async dispatch => {
    try {
        const { data: activity } = await addActivity(activityData)
        return dispatch({
            type: ADD_ACTIVITY,
            payload: activity
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {object} activityData
 */

export const updateActivityAction = activityData => async dispatch => {
    try {
        const { data: activity } = await updateActivity(activityData)
        return dispatch({
            type: UPDATE_ACTIVITY,
            payload: activity
        })
    } catch (err) {
        console.log(err)
    }
}

/**
 *
 * @param {string} structureId
 * @param {string} activityId
 */

export const deleteActivityAction = (structureId, activityId) => async dispatch => {
    try {
        await deleteActivity(structureId, activityId)
        return dispatch({
            type: DELETE_ACTIVITY,
            payload: activityId
        })
    } catch (err) {
        console.log(err)
    }
}
