import { SOCKET } from '../actions/actions'

const socket = store => next => action => {
    let result = next(action)
    if (action.type === SOCKET) {
        return { auth: true, socket: action.payload }
    }

    return result
}

export default socket
