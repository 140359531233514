require('dotenv').config()

function defineUrlApi(env) {
    switch (env) {
        case 'production':
            return `https://gateway.hosmoz.care`
        case 'prod':
            return `https://gateway.hosmoz.care`
        case 'staging':
            return `https://gateway.stg-hosmoz.com`
        case 'stg':
            return `https://gateway.stg-hosmoz.com`
        default:
            return `http://localhost:3004`
    }
}

function defineRealtimeUrl(env) {
    switch (env) {
        case 'production':
            return `https://realtime.hosmoz.care`
        case 'prod':
            return `https://realtime.hosmoz.care`
        case 'staging':
            return `https://realtime.stg-hosmoz.com`
        case 'stg':
            return `https://realtime.stg-hosmoz.com`
        case 'local':
            return `http://localhost:3066`
        default:
            return `http://localhost:3066`
    }
}

function defineRealtimeIotUrl(env) {
    switch (env) {
        case 'production':
            return `https://realtime-iot.hosmoz.care`
        case 'prod':
            return `https://realtime-iot.hosmoz.care`
        case 'staging':
            return `https://realtime.stg-hosmoz.com`
        case 'stg':
            return `https://realtime.stg-hosmoz.com`
        case 'local':
            return 'http://localhost:4001'
        default:
            return 'http://localhost:4001'
    }
}

const defineMessagingV2Url = env => {
    switch (env) {
        case 'prod':
            return 'https://messaging-v2.hosmoz.care/'
        case 'production':
            return 'https://messaging-v2.hosmoz.care/'
        case 'staging':
            return 'https://messaging.stg-hosmoz.com'
        case 'local':
            return 'http://localhost:3666'
        default:
            return 'http://localhost:3666'
    }
}

const defineMessagingV2Ws = env => {
    switch (env) {
        case 'production':
            return 'wss://messaging-v2.hosmoz.care'
        case 'staging':
            return 'wss://messaging.stg-hosmoz.com'
        case 'local':
            return 'ws://localhost:3666'
        default:
            return 'ws://localhost:3666'
    }
}

module.exports = {
    url_gateway: defineUrlApi(process.env.REACT_APP_ENV),
    url_realtime: defineRealtimeUrl(process.env.REACT_APP_ENV),
    url_realtime_iot: defineRealtimeIotUrl(process.env.REACT_APP_ENV),
    url_messaging_v2: defineMessagingV2Url(process.env.REACT_APP_ENV),
    ws_messaging_v2: defineMessagingV2Ws(process.env.REACT_APP_ENV)
}
