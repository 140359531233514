import {
    EVENT,
    LASTRDV,
    NEXTRDV,
    PLANNING,
    PLANNING_ALL,
    PRO_SELECTED,
    ABSENCE_PRO,
    SEARCHEVENT
} from '../actions/actions'

const initialState = {
    planning: [],
    event: false,
    nextRdv: [],
    lastRdv: [],
    proSelected: null,
    absence_pro: {},
    searchEvent: []
}

const planningReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCHEVENT:
            return {
                ...state,
                searchEvent: action.payload
            }
        case ABSENCE_PRO:
            return {
                ...state,
                absence_pro: action.payload
            }
        case NEXTRDV:
            return {
                ...state,
                nextRdv: action.payload
            }
        case LASTRDV:
            return {
                ...state,
                lastRdv: action.payload
            }
        case PLANNING:
            return {
                ...state,
                planning: action.payload
            }

        case EVENT:
            return {
                ...state,
                event: action.payload
            }

        case PLANNING_ALL:
            return {
                ...state,
                all: action.payload
            }

        case PRO_SELECTED:
            return {
                ...state,
                proSelected: action.payload
            }
        default:
            return state
    }
}

export default planningReducer
