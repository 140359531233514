import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Drawer from '../Drawer/Drawer'
import Header from './Header'

import config from '../../config'
import * as actions from '../../store/actions/actions'
import { getCookie, removeCookie } from '../../utils/manageCookies'
import { getFromSessionStorage, setToSessionStorage } from '../../utils/manageSessionStorage'
import { freeMessages } from '../../store/actions/actions'
import globalSocket from '../../hooks/sockets/globalSocket'

const ROOT_URL = config.url_realtime

class Base extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            token: ''
        }
    }

    componentWillMount() {
        this.props.actions.getBaseAction()
        const token = getFromSessionStorage('sessionToken') || getCookie('sessionToken')
        const userid = getFromSessionStorage('_uid') || getCookie('_uid')

        if (token && !getFromSessionStorage('sessionToken')) setToSessionStorage('sessionToken', token)

        //const socket = io(ROOT_URL, { query: 'token=' + token + '&userid=' + userid })
        // const socket2 = io('http://localhost:4000')
        //this.props.actions.socketaction(socket)
        // console.warn({ socket, socket2 })
        if (getCookie('sessionToken')) removeCookie('sessionToken')
        if (getCookie('_uid')) removeCookie('_uid')
        this.props.actions.getPermissionsAction()
        this.props.actions.freeMessages()
    }

    componentWillUpdate() {
        const token = getFromSessionStorage('sessionToken') || getCookie('sessionToken')
        if (!token && getFromSessionStorage('_expired') === 'true') {
            window.location.href = '/login/?expired=true'
        } else if (!token) {
            window.location.href = '/login'
        }
    }

    render() {
        return (
            <div className="wrapper" style={{ overflowY: 'hidden', height: '100%' }}>
                <Header allprops={this.props} location={this.props.location} />
                <section style={{ paddingTop: '80px', height: '100%', overflowY: 'auto' }}>
                    {this.props.children}
                </section>
                <Drawer />
            </div>
        )
    }
}

Base.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    socket: PropTypes.object
}

const mapStateToProps = state => ({ settings: state.settings, auth: state.auth, socket: state.socket })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(Base)
