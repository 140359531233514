import { trackError } from '../../api/error'

/**
 *
 * @param {string} path
 * @param {object} error
 */

export const trackErrorAction = async (path, error) => {
    try {
        await trackError(path, error.stack.toString())
        return
    } catch (err) {
        console.log(err)
    }
}
